@mixin installments {
  .elc-product {
    &-installments-wrapper {
      .elc-product-installments-price {
        padding: 0;
      }
    }
    &-quick-view-details-wrapper {
      .elc-product-installments-price {
        padding: 0;
      }
    }
  }
  .elc-product-full {
    .elc-grid-column {
      .elc-product-installments-price {
        padding: 0 0 5px;
        @media (min-width: 768px) {
          order: 7;
        }
        .elc-product-installments-price-label {
          margin-top: 0;
        }
      }
    }
  }
}
