body.toolbar-drawer {
  padding-top: 0;
}

.pg_wrapper {
  margin: 0;
}

.device-mobile {
  a {
    input {
      font-size: 18px;
      font-weight: normal;
      letter-spacing: 0;
    }
  }
}

form label {
  display: block;
  font-size: 14px;
  margin: 0 0 8px;
}
/* Override cl_base.... */
#payment.device-mobile {
  .form_element {
    label {
      text-transform: none;
      width: 190px;
      display: block;
      font-size: 14px;
      margin: 0 0 8px;
    }
  }
}

#payment.device-mobile {
  .form_element.default_payment_container {
    label {
      text-transform: uppercase;
    }
  }
}

#payment.device-mobile {
  h2 {
    font-size: 30px;
  }
}

.device-mobile .form_element.radio,
.device-mobile .form_element.checkbox {
  label {
    display: inline; /* :( */
  }
}

.device-mobile {
  a.add-address-link {
    input {
      width: 100%;
    }
  }
}

.device-mobile {
  .column {
    .controls {
      overflow: hidden;
      .payment-details {
        float: left;
        margin: 5px 5px 0 0;
      }
    }
  }
}

.device-mobile {
  .form_element {
    input {
      width: 100% !important;
    }
  }
}

#main.single {
  .panel {
    a.change-address {
      color: #5dba98;
    }
    .accepted-privacy-policy {
      .pc_hidden {
        display: inline !important;
      }
    }
  }
  #confirmation-panel {
    div.print-buttons {
      display: block !important;
      a {
        height: 50px !important;
        line-height: 25px !important;
      }
    }
  }
}

.mobile_mpp {
  .not_shaded_sized {
    .spp_product_status {
      .coming_soon_stock_msg {
        a.btn-tempoutofstock {
          font-size: 10px;
          line-height: 35px;
        }
      }
    }
  }
}
/* Account */
#signin {
  .show_password-form-container {
    label {
      display: inline-block;
    }
  }
}

#registration {
  #newsletter-info {
    fieldset.email_promotions {
      h3 {
        margin-bottom: 0.5em;
      }
      .form_element.sms_promo_container {
        label {
          a {
            display: inline-block;
          }
        }
      }
      .phone_container {
        margin-top: 0.5em;
        span.prefix {
          display: block;
          float: left;
          line-height: 2.5em;
        }
        input.sms_input {
          float: left;
          width: auto !important; /* Yuck! DH */
          margin-left: 1em;
        }
      }
    }
  }
}

#address_book {
  .body_container {
    #content {
      .payment_book_container {
        border: 0;
      }
    }
  }
  .address-book-info {
    .add-address-link,
    .column {
      margin: 0 0 20px;
      display: block;
    }
  }
  .add_new_creditcard {
    padding: 0;
    margin: 0;
    .add-address-link {
      margin: 0 0 20px;
      display: block;
    }
  }
  .payment_book_container {
    .column {
      margin: 0 0 20px;
    }
  }
}

#payment {
  .main_container {
    #myaccount-wrapper {
      padding: 0 10px 20px;
    }
  }
  fieldset.fs,
  fieldset.payment_address {
    margin: 0 10px 0 0;
    width: auto;
  }
  .checkbox {
    input {
      width: auto;
    }
    input,
    label {
      display: inline;
    }
  }
}

#payment_info {
  fieldset.fs,
  fieldset.payment_address {
    margin: 0 10px 0 0;
    width: auto;
  }
  .page-header,
  .add_payment_link,
  .column {
    margin: 0 0 20px;
    display: block;
  }
  .billing_address,
  .controls {
    margin: 10px 0 0;
  }
}

#past_purchases {
  .account-past-purchases {
    .shipment-products {
      .product-header {
        display: none;
      }
    }
    .past-purchases {
      .order-info.accordion-content {
        .products {
          .product-footer {
            .sales_tax {
              display: none;
            }
          }
        }
      }
    }
  }
}

form#password_reset {
  input {
    display: block;
  }
  label {
    display: inline-block;
  }
}
/* Samples page */
body#samples #samples-panel .btn {
  @extend input.button[type='submit'];
}
//body#samples #samples-panel .add-button { display: none; }
body#samples .panel {
  padding: 10px 15px;
}

body#samples .loading {
  background: url(/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif) no-repeat center center;
}

body#samples .loading * {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
}

body#samples {
  .samples-page {
    margin-bottom: 0 !important;
    #samples-panel {
      header {
        border-bottom: 1px solid #dbdbdb;
        border-top: 1px solid #dbdbdb;
        h2 {
          font-size: 24px;
        }
      }
      .add-button {
        float: none !important;
        margin-bottom: 20px !important;
        margin-right: 0 !important;
        input {
          font-size: 16px;
        }
      }
      .return-link {
        float: none !important;
        text-align: center;
      }
      .product-list {
        .product {
          width: 30% !important;
        }
        .sku {
          .sample-select-button {
            background-color: transparent;
            color: black;
            display: inline !important;
            font-size: 12px;
            height: 0;
            line-height: 0;
            padding-top: 0;
            text-transform: none;
          }
        }
      }
    }
  }
}
/* Panels */
#viewcart #promo-panel .banner img {
  width: 100%;
}

@media (max-width: 768px) {
  div#main.single {
    section#viewcart-panel header.viewcart-header {
      display: block;
      border-top: 1px solid $color-shade-grey;
      border-bottom: 1px solid $color-shade-grey;
      padding-right: 0px;
      h2 {
        float: none;
      }
      .item-count {
        text-align: left;
        margin: 0;
        padding: 10px 0 0;
      }
      .checkout-message {
        margin-top: 20px;
      }
    }
    .viewcart-panel {
      .viewcart-panel__title {
        font-size: 20px !important;
      }
      .item-count {
        font-size: 14px !important;
        width: 100% !important;
      }
      .product_subname {
        padding-bottom: 10px;
        width: 100% !important;
      }
      .cart-item__size {
        padding-top: 10px;
      }
      .cart-item__qty {
        float: right !important;
        padding: 0px !important;
        form {
          display: inline-block !important;
        }
      }
    }
  }
}

.footer-top-mobile-v1 {
  .form-text-input {
    margin-right: 0;
    width: 82%;
  }
  .form-text-span {
    float: left;
    margin-top: 20px;
    width: 18%;
  }
}

#foreground-node {
  width: 100% !important;
  height: auto !important;
  div.cms_layer {
    width: 100% !important;
    position: relative !important;
    top: 0px !important;
  }
  table {
    width: 90% !important;
  }
}

#footer {
  height: auto !important;
  margin: -1px !important;
}

#main.single {
  #guarantee-panel {
    background-color: $color-white !important;
    h2 {
      font-weight: normal;
      font-size: 15px !important;
    }
  }
}

.deactivate {
  .product-img img,
  label {
    opacity: 0.3;
  }
  .product-name {
    color: #cccccc !important;
    cursor: default !important;
  }
  .sample-select-button {
    background-color: #cccccc !important;
    cursor: default !important;
  }
}

body#samples {
  #main.single {
    .viewcart-buttons-panel {
      .edit-cart {
        margin-top: 13px;
      }
    }
    .samples-panel {
      p.available {
        margin-top: 0;
      }
    }
  }
}

body#viewcart {
  #main.single {
    .viewcart-buttons-panel {
      .content {
        float: left;
        width: 100%;
        padding: 0px;
        .continue-buttons {
          width: 100%;
          a.paypal-checkout {
            float: left;
            width: 50%;
            padding: 10px 0 10px 0;
            text-align: center;
          }
          span.or {
            float: left;
            width: 5%;
            text-align: center;
            font-size: 12px;
            margin-top: 20px;
            @media (max-width: 530px) {
              margin-top: 10px;
              display: block;
            }
          }
          a.continue-checkout {
            float: right;
            width: 35%;
            margin-top: 7px;
            padding: 2px 0 2px 0;
            font-size: 12px;
            visibility: hidden;
          }
        }
      }
    }
  }
}

.power-couples-formatter-v1 {
  .accordionItem.power-couples-row-mobile-v1 {
    h3 {
      span.Anti-Blemish {
        background-position: -2px 4px;
        height: 40px;
      }
      span.De-ageing {
        background-position: 0 -74px;
        height: 40px;
      }
    }
  }
}

#header {
  .gnav-search-mobile-v1 .search-icon,
  .gnav-utility-button-v1 .utility-link,
  .gnav-drop-down-v1 .dd-icon .link {
    img {
      height: 56px;
    }
  }
}

#live_chat {
  .chat_items {
    div.chat_product_queries {
      width: 100%;
      text-align: center;
      border-right: none;
    }
  }
  #chat_unavailable {
    .phone_icon,
    .mail_icon {
      width: auto;
    }
  }
  div#chat_window {
    .server {
      span.text {
        a {
          color: #000000;
          font-family: sans-serif;
        }
      }
    }
    .client {
      span.text {
        a {
          font-family: sans-serif;
        }
      }
    }
  }
}

.mobile_mpp {
  #mpp-product-grid {
    .mpp-product {
      .product-info {
        .ppu {
          padding-top: 6px;
        }
      }
    }
  }
}

.mobile_mpp {
  #mpp-product-grid {
    .mpp-product {
      .shaded {
        .ppu {
          padding-bottom: 12px;
        }
      }
    }
  }
}

.content {
  .spp_view {
    .spp-product {
      .mpp_product {
        .ppu {
          margin-left: 15px;
          padding-top: 6px;
        }
      }
    }
  }
}

.mobile_mpp {
  #mpp-product-grid {
    .mpp-product {
      .product-info {
        p.price {
          width: auto;
        }
      }
    }
  }
}

.device-mobile {
  .product.spp-product {
    .mpp_product {
      p.price {
        width: auto;
      }
    }
  }
}

#invoice {
  .messages {
    background-color: transparent;
    border-bottom: medium none;
    color: red;
    line-height: 1.6;
    margin: 5px;
    padding: 0;
  }
}

#past_purchases {
  .account-past-purchases {
    .order-details {
      .order-invoice {
        a {
          height: 35px;
          line-height: 34px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

// Over-ride popup style generated by JS for mobile
@include breakpoint(767px) {
  body #foreground-node {
    &.privacy-policy-overlay,
    &.generic-helplinks-overlay {
      top: 0 !important;
      height: 100% !important;
      position: fixed;
    }
  }
  body#samples .pg_wrapper.samples-page #samples-panel {
    ul.product-list {
      width: 100%;
      li.product {
        width: 100% !important;
        margin-right: 0;
        padding-bottom: 1em;
        margin-bottom: 0;
        .details {
          margin-left: 55%;
        }
      }
    }
  }
  body.page-products {
    #mpp-product-grid.product-list {
      .mpp-product {
        a.button {
          font-size: 15px;
        }
      }
    }
  }
}

.checkout {
  #main.single {
    .links-panel {
      margin-bottom: 80px;
    }
  }
  .shipping-panel {
    .shipping-edit-content {
      .continue-button-wrapper {
        display: none;
      }
    }
  }
  .payment-panel {
    .payment-edit-content {
      .continue-button-wrapper {
        .submit {
          display: none;
        }
      }
    }
  }
}

body {
  &.checkout {
    .page-footer-region {
      background-color: $cr19-bg-white;
    }
  }
  &#viewcart.checkout,
  &#index.checkout.elc-user-state-logged-in {
    .sticky-checkout-button {
      a.btn.btn-primary {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    #main.single #payment-panel {
      a.btn.btn-primary {
        margin: 0;
      }
    }
    .page-footer-region {
      .page-footer {
        .sitewide-footer-formatter {
          &__bottom {
            padding-top: 0;
            &-wrapper {
              .sitewide-footer-formatter__copyright {
                margin: 22px 15px 35px;
              }
            }
          }
        }
      }
    }
  }
  &#confirm {
    background-color: $cr19-bg-white;
  }
}

#address_form_container {
  .address {
    label {
      display: inline-block;
    }
    .state_container {
      label {
        margin-bottom: 10px;
      }
    }
  }
}
