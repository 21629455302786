.block-template-search-mobile-v2 {
  float: left;
}

.device-mobile {
  .site-logo-responsive-v1 {
    a img {
      margin: -20px 0 0;
      padding: 0;
    }
  }
  .gnav-drop-down-v1 {
    .dd-icon {
      em.hide_if_zero {
        top: 17px !important;
        right: 3px !important;
      }
    }
  }
  .navigation_container ul li a {
    padding: 4px 30px 4px 15px;
  }
  .accordionItem.power-couples-row-mobile-v1 {
    h3 {
      span {
        margin-right: 10px;
        &.Acne {
          background-position: -2px 4px;
          height: 40px;
        }
        &.Hidratacion {
          background-position: -2px -155px;
          height: 50px;
        }
        &.Poros {
          background-position: -2px -233px;
          height: 53px;
        }
        &.Enrojecimiento {
          background-position: -2px -312px;
          height: 47px;
        }
        &.three-step-icon-last {
          background-position: 0 -460px;
          float: right;
          height: 40px;
          margin-right: 0;
        }
        &.Anti.Envejecimiento {
          background-position: 0 -74px;
          height: 40px;
        }
        &.Tono.de.Piel.Disparejo {
          background-position: -2px -392px;
          height: 46px;
          margin-right: 0;
        }
        &.Hidratação {
          background-position: -2px -155px;
          height: 50px;
        }
        &.Tom.de.pele.desigual {
          background-position: -2px -392px;
          height: 46px;
        }
        &.Vermelhidão {
          background-position: -2px -312px;
          height: 47px;
        }
      }
      &.selected span.three-step-icon-last {
        background-position: 0 -505px;
      }
    }
  }
  .power-couples-formatter-rows {
    #dark-spots h3 {
      span.heading-text {
        margin-right: 0px;
      }
    }
  }
  .account-past-purchases {
    .past-purchases {
      .order-info.accordion-content {
        .products {
          ul.product-header {
            display: none;
          }
        }
      }
      .order.accordion-header.active {
        .view-details {
          a {
            span.close {
              bottom: 0px;
              top: 55px;
              width: 22px;
            }
          }
        }
      }
      .order-details {
        a.button.add-all-to-bag {
          height: auto;
          line-height: 20px;
          padding: 7px;
          font-size: 16px;
        }
      }
    }
  }
  .account-block-container {
    .page_subbox_container {
      margin-right: 0px;
      a.defaultbill-address {
        input {
          font-size: 15px;
          width: 100%;
          word-wrap: break-word;
        }
      }
    }
    .address_data {
      a.default-address {
        input.small-button {
          font-size: 15px;
          width: 100%;
          word-wrap: break-word;
          margin-bottom: 10px;
        }
      }
    }
    .address_but_wrap {
      a.edit-address {
        width: 48%;
        float: left;
        input.small-button {
          width: 100%;
        }
      }
      a.delete-address {
        width: 48%;
        float: left;
        input.small-button {
          width: 100%;
        }
      }
    }
  }
  .add_new_creditcard {
    a.add-address-link {
      margin: 0 10px 10px 10px !important;
      input.view-button {
        word-wrap: break-word;
        font-size: 15px;
        padding: 8px;
      }
    }
  }
  .page_subbox_container.column {
    margin: 0 10px 10px !important;
    .address_but_wrap {
      a {
        input.delete-payment {
          width: 48%;
          margin-left: 0px;
        }
      }
    }
  }
  .main_container {
    .product.spp-product .shaded {
      .spp_product_status {
        .temp_out_of_stock_msg {
          margin-bottom: 15px;
        }
      }
    }
    .mpp_product {
      display: block !important;
    }
  }
  #address_form_container {
    .ship_type_container {
      input {
        width: 30px !important;
      }
    }
    .default_ship_container,
    .default_bill_container {
      input {
        float: left;
        width: 20px !important;
      }
    }
    #address_form_container .page_subbox_container {
      fieldset.fs {
        div.radio {
          input {
            width: auto !important;
          }
        }
        div.radio.default_ship_container {
          span {
            display: inline !important;
          }
        }
        div.radio.default_bill_container {
          span {
            display: inline !important;
          }
        }
      }
    }
  }
  .elc-user-state-logged-in {
    .navigation_container .field-mobile-menu {
      ul.menu {
        li.parent-iniciar-sesion {
          display: none;
        }
      }
    }
  }
  .elc-user-state-anonymous {
    .navigation_container .field-mobile-menu {
      ul.menu {
        li.parent-salir {
          display: none;
        }
      }
    }
  }
  #header {
    #showsearch {
      input.searchInputs {
        width: 76%;
      }
    }
  }
  .sbHolder.sized {
    &.Fragrance {
      background-color: #f1f4f5;
    }
  }
  #top #header {
    .btn_navigation {
      background-position: 0 -9px;
      height: 52px;
    }
    .block-template-search-mobile-v2 {
      float: left;
    }
    .site-logo-responsive-v1 a {
      height: 56px;
      line-height: 56px;
      margin: 0;
      padding: 0;
    }
    .gnav-drop-down-v1 .dd-icon .link em {
      padding: 0;
      right: 3px;
      text-align: center;
      top: 11px;
    }
  }
  .spp_reviews {
    #BVFieldRecommendContainerID {
      #BVFieldRecommendLabelID {
        width: 100%;
        float: left;
      }
    }
    #BVFieldContextdatavalueDidYouReceiveAFreeSampleContainerID {
      #BVFieldContextdatavalueDidYouReceiveAFreeSampleLabelID {
        width: 100%;
        float: left;
      }
    }
  }
  @media only screen and (max-device-width: 768px) and (orientation: landscape) {
    .spp_reviews {
      #BVRRSecondarySummaryContainer {
        .BVRRSecondaryRatingSummary.BVRRSecondaryRatingSummaryNoReviews {
          padding: 0 0 65px 25px;
          #BVRRRatingSummaryNoReviewsWriteImageLinkID {
            a {
              width: 250px;
            }
          }
        }
        #BVRRRatingSummaryLinkWriteFirstID {
          a,
          .BVRRRatingSummaryLinkWriteFirstPrefix {
            font-size: 20px;
          }
        }
      }
    }
  }
  div#main.single {
    section#need-help-panel .phone,
    section#need-help-panel .chat,
    section#need-help-panel .email {
      padding-left: 26px;
    }
    .sign-in-panel {
      #new-or-returning-radios fieldset {
        width: 100%;
      }
    }
    .form-container {
      .first-name,
      .last-name,
      .maiden-name,
      .address1,
      .address2,
      .address3,
      .address4,
      .address5,
      .state,
      .city-and-other,
      .municipality,
      .colony-and-other,
      .city,
      .city-entered,
      .colony,
      .colony-entered,
      .postal-code {
        width: 100%;
      }
    }
    #shipping-panel .select-address .sub-section select {
      width: 100%;
    }
  }
  .sbHolder {
    .sbOptions {
      max-height: none;
    }
  }
  .device-mobile #search #search-results .results .result .badge {
    .badge-inner.badge_data_1,
    .badge-inner.badge_data_30 {
      display: none;
    }
    .badge-inner.badge_data_1.new,
    .badge-inner.badge_data_30.bestseller {
      display: inline-block;
    }
  }
  .my-account-favorites-mobile-v1 {
    .tabs {
      .tabs-content {
        .add-to-bag {
          padding: 6px 0;
          line-height: normal;
        }
      }
    }
  }
  .section-international {
    .basic-content-mobile-v1 {
      .content {
        table {
          width: 100%;
        }
      }
    }
  }
  .power-couples-formatter-rows {
    .row-products-wrapper {
      .product {
        border-bottom: none;
      }
    }
  }
  .device-mobile {
    .contact_us {
      .form_element.registered_container {
        input.field {
          width: auto !important;
        }
      }
    }
  }
}

.header-gnav-cart {
  .cart-confirm-wrapper {
    div.prod {
      @media (max-width: $cr19-xxsmall) {
        padding-top: 30px;
      }
      .prod-info {
        gap: 10px;
        .cart-product-name a {
          font-size: 0.8rem;
        }
        .qty-price,
        .prod-info__size {
          font-size: 14px;
        }
      }
    }
    .cart-confirm-wrapper__bottom-section .buttons {
      .right-copy a.btn-primary,
      .left-copy a.button.button-secondary {
        @media (max-width: $cr19-xxsmall) {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.gnav-drop-down-v1 {
  .dd-icon {
    .link {
      em {
        right: 2px;
        top: 18px;
      }
    }
  }
}

.address_lookup_wrapper {
  border: 1px solid #bbbbbb;
  clear: both;
  padding: 10px;
  width: 300px;
  height: 300px;
  overflow: auto;
  li {
    padding-bottom: 1em;
    .action-btn {
      a {
        width: 50%;
        font-size: 12px;
      }
    }
  }
}

.mobile-signup {
  display: none;
}

.shipping-edit-content {
  #checkout_shipping_panel {
    .select-address {
      .new-address {
        .postal-code {
          label {
            margin-bottom: 0px;
          }
        }
        .addresses {
          .shipping_address_container {
            .address1 {
              padding-top: 6px;
            }
          }
        }
      }
    }
  }
  section.gift-options__content {
    display: block;
  }
  #shipping-panel & {
    .address-form {
      .form-item {
        label {
          display: inline;
        }
      }
    }
  }
}

.city-cointainer {
  float: left;
}

.delivery-instructions {
  float: left;
}

.viewcart {
  section.viewcart-cpf-field {
    padding: 0 0 5px 0;
    .postcode-lookup {
      padding-bottom: 10px;
      font-size: 12px;
      .postcode-zipfields-postcode-message {
        float: left;
        width: 100%;
      }
      .postcode-zipfields {
        float: left;
        padding-left: 9px;
        span {
          float: left;
          display: block;
          line-height: 26px;
        }
      }
      label.cpf_label {
        float: left !important;
        display: block;
        line-height: 24px;
        padding-right: 5px;
      }
      input[name='ZIP3'] {
        width: 40px !important;
      }
    }
    .lookup_results_container {
      .address_lookup_wrapper {
        float: right;
        margin: 10px 0 0 0;
      }
    }
  }
}

.state-cointainer {
  float: left;
}

section.section-sms-promotions {
  display: none;
}

.device-mobile {
  &.elc-user-state-logged-in {
    .menu-mlid-296ae432-3c2f-11e3-814d-000c29cc69ea {
      display: none !important;
    }
    .menu-mlid-79c0ac58-3c31-11e3-814d-000c29cc69ea {
      display: inline !important;
    }
  }
  &.elc-user-state-anonymous {
    .menu-mlid-296ae432-3c2f-11e3-814d-000c29cc69ea {
      display: inline !important;
    }
    .menu-mlid-79c0ac58-3c31-11e3-814d-000c29cc69ea {
      display: none !important;
    }
  }
  .store-locator-mobile-v1 {
    .map_container,
    .map_canvas {
      width: 100% !important;
      .gm-style-iw,
      .gm-style-iw > div {
        max-width: 200px !important;
        min-width: 150px !important;
      }
    }
    .map_container {
      .scroll-overlay {
        pointer-events: none;
      }
    }
  }
}

#main.single {
  .checkout__content {
    #registration-panel {
      .checkout__panel-content {
        .how-will-we-use {
          display: none;
        }
      }
    }
  }
  .panel {
    .email-address,
    .password,
    .full-name,
    .federal-number {
      width: 100%;
    }
  }
}

#main.single {
  #payment-panel {
    .payment-edit-content {
      #payment-information {
        display: block;
        height: 90px;
      }
    }
  }
}

.address_lookup_wrapper {
  width: 100%;
}

#BVRRSummaryContainer {
  #BVRRRatingSummaryLinkWriteFirstID {
    display: block;
    margin-top: 5px;
  }
}

@media (max-width: 768px) {
  div#main.single {
    section#need-help-panel {
      .phone,
      .email,
      .chat {
        padding-left: 10%;
      }
    }
  }
}

#BVRRSecondarySummaryContainer {
  .BVRRSecondaryRatingSummaryNoReviews {
    padding: 0 0 65px 25px;
    .BVRRCustomNoReviewsPrefix {
      display: block;
      margin-bottom: 3px;
    }
    #BVRRRatingSummaryLinkWriteFirstID {
      padding: 0px;
      float: none;
      a,
      .BVRRRatingSummaryLinkWriteFirstPrefix {
        font-family: 'HelveticaNeueLTStd45Light', 'Helvetica Neue', Helvetica, Arial, Sans-Serif;
        letter-spacing: -0.1mm;
        font-weight: normal;
        line-height: normal;
        color: #666;
        font-size: 17px;
      }
    }
    #BVRRRatingSummaryNoReviewsWriteImageLinkID {
      top: 56px;
      width: auto;
    }
  }
}

#registration.device-mobile {
  .registration_content {
    #profile_preferences {
      .email_promotions {
        #providing-email {
          width: 90%;
        }
      }
    }
  }
}

.formattedInstallmentPrice {
  color: #db1f8e;
}

#confirm {
  .wrapper {
    #top_wrapper {
      #header {
        .btn_navigation {
          display: none;
        }
        .content {
          .field-mobile-template {
            .search-icon {
              display: none;
            }
          }
        }
      }
    }
  }
}

body {
  #foreground-node {
    .close-container {
      position: absolute;
    }
  }
}

.checkout__content {
  .payment-edit-content {
    .payment-type {
      .payment-container {
        .payment-form {
          .payment-form__pp_cash {
            .cash_type_field {
              display: none;
            }
          }
        }
      }
    }
  }
}
// updated for Bugzilla Bug-266799
.messages.public {
  display: none;
}

.field-mobile-menu {
  ul {
    li.parent-carrinho-de-compras {
      .checkout-icon:first-child {
        background: url('/media/export/cms/icon-bag.png') no-repeat scroll 0 3px transparent;
        display: table-cell;
        height: 28px;
        margin-left: 3px;
        margin-top: 3px;
        width: 28px;
      }
      span.checkout {
        display: table-cell;
        line-height: 25px;
      }
    }
  }
}

#main.single #order-summary-panel {
  .order-summary__content {
    p.order-summary__shipping-method {
      width: 35%;
      font-weight: bold;
    }
    p.index-page-shipmethodtext {
      padding-top: 30px;
    }
    .cpf-field {
      float: right;
      width: auto;
      margin-right: 5px;
      section.viewcart-cpf-field {
        padding: 0 0 5px 0;
      }
      input {
        width: 100%;
        height: 24px;
        line-height: 1.4em;
      }
    }
    #checkout_shipmethod {
      width: auto;
    }
  }
}

#viewcart {
  #main.single #order-summary-panel {
    .order-summary__content {
      p.order-summary__shipping-method {
        padding-top: 26px;
      }
      p.index-page-shipmethodtext {
        display: none;
      }
      .cpf-field {
        width: 30%;
      }
    }
  }
}

body#index {
  #main.single {
    #order-summary-panel {
      .order-summary__content {
        p.viewcart-page-shipmethodtext {
          display: none;
        }
      }
    }
  }
}

.order-timeline {
  ul {
    display: flex;
    max-width: 340px;
    margin: auto;
  }
}

.order-timeline__step {
  text-align: center;
  position: relative;
  pointer-events: none;
  opacity: 0.3;
  flex: 1;
  + .order-timeline__step {
    margin-left: 15px;
    &:before {
      content: '';
      border-top: 1px solid $color-cl-green-2;
      width: 100%;
      position: absolute;
      top: 8px;
      transform: translate(calc(-100% - 8px));
    }
  }
  + .order-timeline__step_large {
    &:before {
      width: calc(100% - 14px);
    }
    + .order-timeline__step {
      &:before {
        width: calc(100% + 12px);
      }
    }
  }
}

.order-timeline__step_active {
  opacity: 1;
  pointer-events: auto;
  .order-timeline__step-bullet {
    background-color: $color-cl-green-2;
    border: 0;
  }
}

.order-timeline__step.order-timeline__step_large {
  .order-timeline__step-title {
    max-width: none;
  }
}

.order-timeline__step-bullet {
  background-color: $color-white;
  border: 1px solid $color-cl-green-2;
  border-radius: 100%;
  font-size: 14px;
  color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
  width: 17px;
  height: 17px;
}

.order-timeline__step_cancel .order-timeline__step-bullet {
  background-color: $color-cl-pink;
}

.order-timeline__step-title {
  font-size: 14px;
  max-width: 60px;
  margin: auto;
  font-weight: normal;
}

.order-timeline__step-subtitle {
  font-family: inherit;
  font-size: 10px;
}

/* reusable modernizr classes */
html.no-js .nojs_hidden,
html.js .js_hidden {
  display: none;
}

/* things get border-box so we can add padding and margins to 100% width elements */
.panel,
.panel .column,
.column.left,
.column.right,
input,
textarea,
.pg_wrapper,
#checkout-header,
.overlay-container,
fieldset {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pre {
  white-space: pre;
}

/* things get auto-clearfixed to DRY up classes in HTML */
.panel,
.panel header,
.row,
.product {
  *zoom: 1;
}

.panel:before,
.panel header:before,
.row:before,
.product:before,
.panel:after,
.panel header:after,
.row:after,
.product:after {
  display: table;
  line-height: 0;
  content: '';
}

.panel:after,
.panel header:after,
.row,
.product {
  clear: both;
}

/* universal panel styles */
.panel {
  border: 1px solid #ccc;
  overflow: hidden;
  .block.right {
    float: right;
  }
  .content {
    padding: 1em;
    header {
      padding: 0.2em 0;
    }
  }
  header {
    border-bottom-width: 0;
    margin: 0;
    background-color: #ccc;
    font-weight: bold;
    h2,
    .item-count {
      font-size: 1.4em;
      float: left;
      padding: 0.3em 0.5em;
      font-weight: bold;
      border-width: 0;
      margin: 0;
      line-height: 1.2em;
    }
    .edit {
      float: right;
      margin: 2px 1em 0 0;
      text-transform: uppercase;
    }
    h2 .edit {
      float: none;
      margin: 0;
      text-transform: none;
    }
  }
  h3 {
    font-size: 1.2em;
    font-weight: bold;
    border-bottom-width: 0;
  }
  .form-item {
    margin: 1em 0;
    width: max-content;
  }
  .form-item-dropdown {
    width: 100%;
  }
  .shade {
    padding-bottom: 0.3em;
    width: 200px;
  }
  label {
    display: block;
    max-width: 100vw;
    padding: 0 10px;
  }
  .checkbox input {
    height: auto;
  }
  .checkbox label {
    display: inline;
  }
  p {
    margin-bottom: 1em;
  }
}

.loading {
  background: url(/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif) no-repeat center center;
  * {
    opacity: 0.8;
    filter: alpha(opacity=80);
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
  }
}

.column.left {
  float: left;
  clear: left;
  width: 65%;
  padding-right: 2em;
}

.column.left .panel {
  margin-bottom: 0.3em;
}

.column.right {
  float: right;
  clear: right;
  width: 35%;
}

.column fieldset.fs {
  width: 100%;
}

.panel > .column {
  float: left;
  border-right: 1px solid #ccc;
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}

/* margin and padding bottom hacks equal box height */
.panel > .column:first_of_type {
  clear: left;
}

.panel > .column:last-of-type {
  border-right-width: 0;
}

.panel > .column > :last-child {
  margin-bottom: 1em;
}

/* replaces bottom padding killed by box-height hack -- should be equal to .panel .column padding above */
#viewcart .panel footer {
  float: right;
  margin: 5px;
}

#review-panel .sub-section {
  margin-left: 0em;
  margin-top: 10px;
}

#promo-panel.panel {
  border: none;
}

#promo-panel.panel .banner {
  margin-bottom: 0.4em;
  padding: 0.1em;
}

.panel .sub-section {
  margin-left: 2em;
}

.panel .sub-section .sub-section {
  margin: 0;
}

html.no-js .panel .sub-section {
  margin-left: 0em;
}

.pg_wrapper {
  overflow: hidden;
}

.messages {
  margin: 1em;
  font-size: 1.2em;
  font-weight: bold;
  .single-message {
    border: 1px solid #999;
    padding: 0.4em 0.8em;
    background-color: #eee;
    margin-bottom: 0.4em;
    background-color: #ffafa9;
    border-color: #cc6f69;
  }
  .single-message .close_link {
    float: right;
    cursor: pointer;
    display: block;
    margin-left: 1em;
  }
  .single-message.INFORMATION {
    background-color: #fbcc72;
    border-color: #cc9942;
  }
}

#mobile-breadcrumb {
  display: none;
  list-style-type: none;
  margin: 0.5em 0;
  li {
    display: inline-block;
  }
}

.sitewide-footer-formatter__links {
  .footer_ws-link {
    width: 22px;
    height: 22px;
  }
  .footer_privacy-policy {
    color: #f00;
    display: unset;
  }
}
