.clinical-reality__modules--recommended-products {
  .clinical-reality__recommended-products {
    &--description {
      @include start-breakpoint($medium-min) {
        width: 60%;
      }
    }
    &__recommended-products--add-to-bag {
      @include start-breakpoint($medium-min) {
        width: 216px;
        padding: 16px;
      }
    }
  }
}

.clinical-reality {
  &__results-intro {
    &-button-options {
      .clinical-reality--button {
        @include start-breakpoint($medium-m) {
          max-width: 158px;
        }
        @include start-breakpoint($large-min) {
          max-width: 258px;
        }
      }
    }
  }
  &--button {
    @include start-breakpoint($medium-m) {
      padding: 20px;
    }
    @include start-breakpoint($medium-max) {
      max-width: 270px;
      padding: 16px 30px;
    }
  }
  &__mpp {
    &--product {
      &-status {
        line-height: 19px;
      }
    }
  }
  &__recommended-products {
    &--container {
      a.product-add-to-bag {
        @include start-breakpoint($medium-m) {
          min-width: 165px;
          padding: 10px;
          margin-top: 20px;
        }
        @include start-breakpoint($medium-max) {
          min-width: 210px;
          padding: 20px;
        }
      }
    }
  }
  .mobile_hidden {
    @include start-breakpoint($medium-m) {
      max-height: 1100px;
    }
    @include start-breakpoint($medium-max) {
      max-height: inherit;
    }
  }
  .pc_hidden {
    @include start-breakpoint($small-m) {
      max-height: 500px;
    }
  }
}

.clinical-reality__save-results--cta {
  @include start-breakpoint($medium-m) {
    width: 151px;
  }
  @include start-breakpoint($medium-max) {
    width: auto;
  }
}

.loyalty_offer_signup {
  #colorbox {
    &.hidden {
      display: none !important;
    }
  }
  #cboxOverlay {
    &.hidden {
      display: none !important;
    }
  }
}
