// Enhanced Cart Page Styles
@mixin enhanced_cart_styles {
  .spc_enabled {
    .enhanced_cart_page {
      #main.single {
        @media (min-width: $medium-l) {
          padding-bottom: 0;
        }
        .responsive-container {
          .column {
            @media (min-width: $medium-max) {
              &.left {
                width: 70.31%;
                float: $ldirection;
                padding-#{$rdirection}: 24px;
                padding-#{$ldirection}: 25px;
              }
              &.right {
                @include box-shadow(1px 2px 10px $color-light-grey);
                width: 29.69%;
                background: $cr19-bg-light;
                position: absolute;
                #{$rdirection}: 0;
                height: 100%;
              }
            }
            &.left {
              .viewcart-header {
                display: block;
                padding-bottom: 10px;
              }
              .viewcart-panel {
                &.loading {
                  position: relative;
                  background: none;
                  &::before {
                    content: '';
                    background: url(/media/export/cms/479-01%20Clinique_LoadingGif_R22.gif)
                      no-repeat 50% 56%;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    #{$ldirection}: 0;
                    top: 0;
                    z-index: 999;
                    display: block;
                    background-attachment: fixed;
                    @media (min-width: $medium-max) {
                      background-position: 33% 56%;
                    }
                  }
                }
                &__title {
                  text-align: center;
                  font-size: 28px !important;
                  font-weight: bold;
                  line-height: get-line-height(40px, 36px);
                  font-family: $helvetica-bold-font-family;
                  float: none;
                  padding: 0;
                  text-transform: uppercase;
                  @media (min-width: $medium-max) {
                    font-size: 40px !important;
                  }
                }
                &__content {
                  padding: 0;
                }
                .viewcart-samples {
                  padding: 0;
                  margin: 0 auto;
                  font-size: 16px;
                  line-height: get-line-height(16px, 24px);
                  letter-spacing: 0.5px;
                  border-top: 2px solid $color-grey;
                  border-bottom: 2px solid $color-grey;
                  &__header {
                    margin: 13px 16px 13px;
                    @media (min-width: $medium-max) {
                      margin: 13px 16px 0;
                    }
                    h3 {
                      font-family: $helvetica-bold-font-family;
                      font-style: normal;
                      font-weight: bold;
                      font-size: 16px;
                      line-height: get-line-height(22px, 20px);
                      letter-spacing: 0.25px;
                      color: $color-black;
                      margin-bottom: 0;
                      @media (min-width: $medium-max) {
                        font-size: 22px;
                        margin-bottom: 11px;
                      }
                      .samples-expand-toggle {
                        float: $rdirection;
                        position: relative;
                        width: 24px;
                        height: 24px;
                        margin-top: -3px;
                        cursor: pointer;
                        &::before,
                        &::after {
                          @include translate(-50%, -50%);
                          content: '';
                          background: $color-black;
                          display: block;
                          height: 2px;
                          width: 16px;
                          position: absolute;
                          #{$ldirection}: 50%;
                          top: 50%;
                        }
                        &::after {
                          width: 2px;
                          height: 16px;
                        }
                        &.icon--minus::after {
                          visibility: hidden;
                        }
                      }
                    }
                  }
                  &__message {
                    text-align: center;
                    font-size: 20px;
                    margin: 0 auto;
                    font-family: $helvetica-regular-font-family;
                    @media (min-width: $medium-max) {
                      width: 57%;
                    }
                    .max {
                      font-family: $helvetica-regular-font-family;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 11px;
                      line-height: get-line-height(15px, 16px);
                      text-align: center;
                      letter-spacing: 0.25px;
                      color: $color-black-6opacity;
                      margin-top: 0;
                      @media (min-width: $medium-max) {
                        margin-top: 14px;
                        font-size: 15px;
                        line-height: get-line-height(15px, 20px);
                        color: $color-black;
                      }
                    }
                    .available {
                      font-family: $helvetica-regular-font-family;
                      font-style: normal;
                      font-weight: normal;
                      font-size: 11px;
                      line-height: get-line-height(11px, 16px);
                      text-align: center;
                      letter-spacing: 0.25px;
                      color: $color-black-6opacity;
                      margin-top: 0;
                      @media (min-width: $medium-max) {
                        margin-top: 14px;
                        font-size: 15px;
                        line-height: get-line-height(15px, 20px);
                        color: $color-black;
                      }
                    }
                  }
                  &__content {
                    margin-top: 17px;
                    .samples-carousel {
                      .samples-grid {
                        padding: 0 20px;
                        @media (min-width: $medium-max) {
                          padding: 0 30px;
                          margin-bottom: 0;
                        }
                        .slick-track {
                          margin: 0 auto;
                        }
                        .product {
                          padding: 0 7px;
                          text-align: center;
                          min-height: 300px;
                          float: $ldirection;
                          margin: 0;
                          border-bottom: none;
                          &.deactivate {
                            .sample-select-button {
                              background-color: $color-light-grey;
                              cursor: default;
                            }
                          }
                          &:nth-child(n) {
                            display: none;
                            width: 50%;
                          }
                          &:nth-child(1),
                          &:nth-child(2) {
                            display: block;
                          }
                          @media (min-width: $medium-max) {
                            min-height: 366px;
                            padding: 0 10px;
                            &:nth-child(n) {
                              width: 25%;
                            }
                            &:nth-child(3),
                            &:nth-child(4) {
                              display: block;
                            }
                          }
                          &___remove {
                            display: none;
                          }
                          &__container {
                            .product-name {
                              margin-bottom: 12px;
                              font-family: $helvetica-bold-font-family;
                              font-style: normal;
                              font-weight: bold;
                              font-size: 15px;
                              line-height: get-line-height(15px, 20px);
                              text-align: center;
                              letter-spacing: 0.25px;
                              text-transform: uppercase;
                              color: $color-black;
                            }
                            .product-size {
                              font-size: 9px;
                              line-height: get-line-height(9px, 16px);
                              color: $color-black-64opacity;
                              font-family: $helvetica-regular-font-family;
                              @media (min-width: $medium-max) {
                                font-size: 14px;
                                line-height: get-line-height(14px, 16px);
                                color: $color-gray-tier3;
                              }
                            }
                          }
                          &__image {
                            margin: 0 auto;
                            width: 104px;
                            height: 104px;
                            @media (min-width: $medium-max) {
                              width: 152px;
                              height: 152px;
                            }
                            img {
                              width: 100%;
                              height: 104px;
                              @media (min-width: $medium-max) {
                                height: 152px;
                              }
                            }
                          }
                          &__description {
                            margin: 13px 0 85px;
                            .product_name {
                              font-family: $helvetica-regular-font-family;
                              font-style: normal;
                              font-weight: bold;
                              font-size: 14px;
                              line-height: get-line-height(14px, 20px);
                              text-align: center;
                              letter-spacing: 0.25px;
                              text-transform: uppercase;
                              color: $color-black;
                              @media (min-width: $medium-max) {
                                font-size: 15px;
                                width: 100%;
                                height: 82px;
                                max-height: 200px;
                                float: none;
                                overflow: hidden;
                              }
                            }
                            .grid-item--1-3-of-4 {
                              .grid-item--4-of-4 {
                                #{$rdirection}: 0;
                                #{$ldirection}: 0;
                                bottom: 64px;
                                letter-spacing: 0.25px;
                                text-align: justify;
                                @media (min-width: $medium-max) {
                                  bottom: 65px;
                                  height: auto;
                                }
                                font-family: $helvetica-bold-font-family;
                              }
                            }
                          }
                          &__select {
                            margin-bottom: 32px;
                            position: absolute;
                            #{$rdirection}: 0;
                            bottom: 0;
                            #{$ldirection}: 0;
                            @media (min-width: $medium-max) {
                              margin-bottom: 23px;
                            }
                            .button {
                              padding: 7px 0;
                              border: 1px solid $color-grey;
                              margin-top: 0;
                              font-size: 11px;
                              line-height: get-line-height(11px, 20px);
                              text-align: center;
                              color: $color-black;
                              background-color: $color-white;
                              height: auto;
                              min-height: 36px;
                              width: 80%;
                              font-family: $helvetica-regular-font-family;
                              font-weight: normal;
                              @media (min-width: $medium-max) {
                                @include swap_direction(padding, 7px 7px 7px 7px);
                                font-size: 15px;
                                width: auto;
                                max-width: 95%;
                                min-width: 80%;
                              }
                              &.selected {
                                color: $color-white;
                                background-color: $color-black;
                                font-weight: bold;
                              }
                            }
                            .sample-select {
                              padding: 0;
                            }
                          }
                        }
                        .slick-arrow {
                          z-index: 1;
                          &::before {
                            color: $color-black;
                          }
                          &.slick-prev {
                            #{$ldirection}: 3px;
                            #{$rdirection}: auto;
                            &::before {
                              content: '\f104';
                              font: 32px/1 $base-fontawesome-font-family;
                            }
                          }
                          &.slick-next {
                            #{$rdirection}: 3px;
                            #{$ldirection}: auto;
                            &::before {
                              content: '\f105';
                              font: 32px/1 $base-fontawesome-font-family;
                            }
                          }
                        }
                        &.slick-initialized .product {
                          display: block;
                        }
                      }
                    }
                  }
                }
                &.empty_cart {
                  .viewcart-samples,
                  .favorites-panel,
                  .viewcart-banner {
                    display: none;
                  }
                  .error {
                    .single-message {
                      color: $color-carmine;
                      background: $color-bg-carmine-transparent;
                      font-family: $helvetica-regular-font-family;
                      padding: 12px;
                      border: 1px solid $color-carmine;
                      margin-bottom: 16px;
                      font-size: 15px;
                      font-weight: normal;
                      line-height: get-line-height(15px, 20px);
                      letter-spacing: 0.25px;
                    }
                  }
                  .no_items_msg,
                  .signin-to-see-cart {
                    font-family: $helvetica-regular-font-family;
                    font-size: 14px;
                    @media (min-width: $medium-l) {
                      max-width: 420px;
                      margin: 0 auto;
                    }
                  }
                  #favorites-pane {
                    border: 0;
                  }
                }
              }
              #promo-message {
                font-size: 0;
                .dbMsgBox {
                  background: $color-gray-tier1;
                  border: 1px solid $color-gray-dusty;
                  margin: 0 16px 20px;
                  color: $color-gray-dusty;
                  font-size: 15px;
                  font-family: $helvetica-regular-font-family;
                  letter-spacing: 0.25px;
                  font-weight: normal;
                  padding: 15px 12px;
                  text-align: $ldirection;
                  @media (min-width: $medium-max) {
                    margin-#{$ldirection}: 0;
                    margin-#{$rdirection}: 0;
                  }
                }
              }
              .cart-header {
                display: none;
              }
              .messages {
                border: 0;
                padding: 0;
                &.error {
                  .single-message {
                    margin: 25px 0 0;
                    color: $color-red;
                    background: $color-light-orange;
                    border: 1px solid $color-red;
                    padding: 12px;
                    font-size: 15px;
                    font-weight: normal;
                    line-height: get-line-height(15px, 20px);
                    letter-spacing: 0.25px;
                  }
                  [class*='error_offer_criteria_met'] {
                    color: $color-cl-green;
                    border: 1px solid $color-cl-green;
                  }
                  .single-message,
                  .error_offer_criteria_not_met {
                    color: $color-dark-orange;
                    border: 1px solid $color-dark-orange;
                  }
                  .error_invalid_offer_code {
                    border: 1px solid $color-red;
                    color: $color-red;
                  }
                }
              }
              .cart-items {
                .product__desc {
                  @media (min-width: $medium-max) {
                    width: 77%;
                  }
                  .info {
                    & > div {
                      margin-bottom: 10px;
                    }
                    .color {
                      clear: both;
                      margin-top: 0;
                    }
                  }
                }
                .thumb {
                  width: 33.5%;
                  padding-#{$rdirection}: 10px;
                  padding-#{$ldirection}: 0;
                  padding-bottom: 0;
                  float: $ldirection;
                  text-align: center;
                  @media (min-width: $medium-l) {
                    width: 26.5%;
                    padding-#{$rdirection}: 15px;
                  }
                  @media (min-width: $medium-max) {
                    width: 215px;
                    position: absolute;
                    #{$ldirection}: 0;
                    top: 0;
                  }
                }
                .product-name {
                  font-size: 14px;
                  font-weight: bold;
                  a {
                    color: $color-black;
                    font-family: $helvetica-regular-font-family;
                  }
                  @media (min-width: $medium-l) {
                    font-size: 17px;
                  }
                }
                .size {
                  font-size: 12px;
                  color: $color-black;
                  opacity: 0.4;
                  @media (min-width: $medium-l) {
                    font-size: 14px;
                    opacity: 0.64;
                  }
                }
                .color {
                  height: 18px;
                  @media (min-width: $medium-l) {
                    float: $ldirection;
                    width: 47%;
                  }
                  .swatch {
                    border-radius: 50%;
                    margin-#{$rdirection}: 8px;
                  }
                  .shade {
                    font-size: 12px;
                    opacity: 0.64;
                    @media (min-width: $medium-l) {
                      font-size: 14px;
                      color: $color-black;
                      #{$ldirection}: 0;
                    }
                  }
                }
                .cart-item {
                  border-bottom: 2px solid $color-grey;
                  margin: 16px 0;
                  border-top: 2px solid $color-grey;
                  padding: 12px 16px;
                  @media (min-width: $medium-max) {
                    padding-#{$ldirection}: 215px;
                    padding-#{$rdirection}: 27%;
                    margin: 25px 0;
                  }
                  &.sample {
                    @media (min-width: $medium-max) {
                      min-height: 135px;
                    }
                    .total {
                      display: none;
                    }
                    .info {
                      position: relative;
                      @media (min-width: $medium-max) {
                        position: static;
                      }
                    }
                    .price {
                      text-align: $rdirection;
                      position: absolute;
                      #{$rdirection}: 0;
                      bottom: -38px;
                      width: auto;
                      margin: 0;
                      font-weight: bold;
                      .price-wrapper {
                        width: auto;
                        margin-bottom: 0;
                      }
                      @media (min-width: $medium-max) {
                        float: $rdirection;
                        padding-#{$rdirection}: 0;
                        position: static;
                        margin-top: -7px;
                      }
                      .cart-item__total {
                        display: none;
                      }
                    }
                  }
                }
                .cart-item__remove-form {
                  padding-top: 0;
                  position: static;
                  .cart-item__add-to-favorites {
                    text-decoration: underline;
                    font-family: $helvetica-regular-font-family;
                    font-size: 14px;
                    color: $color-gray-dusty;
                    display: inline;
                    @media (min-width: $medium-l) {
                      float: $rdirection;
                      width: 53%;
                    }
                    @media (min-width: $medium-max) {
                      width: auto;
                    }
                  }
                }
                .product_name {
                  font-size: 12px;
                  font-weight: bold;
                  line-height: get-line-height(12px, 24px);
                  a {
                    color: $color-black;
                    font-weight: bold;
                    font-family: $helvetica-bold-font-family;
                  }
                  @media (min-width: $medium-l) {
                    font-size: 17px;
                  }
                  @media (min-width: $medium-max) {
                    margin-#{$rdirection}: -70px;
                  }
                }
                .price {
                  margin-top: 0;
                  width: 30%;
                  padding-top: 0;
                  line-height: 1.25;
                  @media (min-width: $medium-max) {
                    width: 50%;
                    font-size: 17px;
                    padding-top: 0;
                    line-height: normal;
                  }
                  &.column {
                    .price-wrapper {
                      width: auto;
                      margin-bottom: 0;
                    }
                  }
                  .cart-item__disc-unit-price {
                    opacity: 0.241904;
                    font-size: 14px;
                    font-family: $helvetica-regular-font-family;
                    margin-#{$rdirection}: 10px;
                    float: $ldirection;
                    @media (min-width: $medium-max) {
                      font-size: 17px;
                      margin-#{$rdirection}: 20px;
                    }
                  }
                  .cart-item__unit-price {
                    float: $ldirection;
                    font-family: $helvetica-regular-font-family;
                  }
                }
                .desc {
                  width: 66%;
                  padding: 0;
                  @media (min-width: $medium-l) {
                    width: 73.5%;
                  }
                  @media (min-width: $medium-max) {
                    width: 100%;
                    padding-bottom: 15px;
                  }
                }
                .total {
                  text-align: $rdirection;
                  @media (min-width: $medium-l) {
                    width: 14%;
                    float: $rdirection;
                    font-size: 17px;
                    padding-top: 0;
                  }
                  @media (min-width: $medium-max) {
                    width: 25%;
                  }
                }
                .qty {
                  padding: 0;
                  width: 66%;
                  @media (min-width: $medium-l) {
                    width: 73.5%;
                  }
                  #cart {
                    position: relative;
                    display: inline-block;
                    &:before {
                      content: '';
                      position: absolute;
                      height: 100%;
                      #{$rdirection}: 30px;
                      top: 0;
                      border-#{$rdirection}: 1px solid $color-black;
                    }
                    @media (min-width: $medium-l) {
                      &:after {
                        content: '';
                        position: absolute;
                        border: 0.3em solid $color-black;
                        border-color: $color-black transparent transparent transparent;
                        top: 16px;
                        #{$rdirection}: 11px;
                        pointer-events: none;
                      }
                    }
                    .selectbox {
                      @include swap_direction(padding, 0 42px 0 9px);
                      background-image: linear-gradient(
                          45deg,
                          $color-bg-white-transparent 50%,
                          $color-black 50%
                        ),
                        linear-gradient(135deg, $color-black 50%, $color-bg-black-transparent 50%),
                        linear-gradient(to bottom, $color-white, $color-white);
                      background-position: calc(100% - 16px) calc(1em + -4px),
                        calc(100% - 11px) calc(1em + -4px), 100% 0;
                      border-radius: 0;
                      font-family: $helvetica-bold-font-family;
                      height: 38px;
                      border: 1px solid $color-black;
                      font-size: 20px;
                      @media (min-width: $medium-l) {
                        @include appearance(none);
                        background-image: none;
                        border: 1px solid $color-black;
                        outline: 0;
                      }
                      @media (min-width: $medium-max) {
                        font-size: 20px;
                        height: 1.8em;
                      }
                    }
                  }
                  @media (min-width: $medium-max) {
                    width: 25% !important;
                  }
                }
              }
              #viewcart-panel {
                .remove_link {
                  @include rotate(45deg);
                  width: 24px;
                  height: 24px;
                  background: none;
                  margin-top: 15px;
                  margin-#{$rdirection}: 15px;
                  &::before,
                  &::after {
                    @include translate(-50%, -50%);
                    content: '';
                    background: $color-black;
                    display: block;
                    height: 2px;
                    width: 16px;
                    position: absolute;
                    #{$ldirection}: 50%;
                    top: 50%;
                  }
                  &::after {
                    width: 2px;
                    height: 16px;
                  }
                }
                .sample .remove_link {
                  margin: 0;
                  @media (min-width: $medium-max) {
                    margin-top: 15px;
                    margin-#{$rdirection}: 15px;
                  }
                }
              }
              .viewcart-banner {
                display: block;
                background: none;
                margin: 0;
                text-align: $ldirection;
                padding-top: 0;
                padding-bottom: 0;
                border-width: 2px;
                border-#{$ldirection}: 0;
                border-#{$rdirection}: 0;
                @media (min-width: $medium-l) {
                  padding: 4px 15px;
                }
                &__accordion {
                  position: relative;
                }
                h3 {
                  font-size: 16px;
                  font-family: $helvetica-bold-font-family;
                  font-weight: bold;
                  color: $color-black;
                  text-transform: uppercase;
                  padding: 12px 0;
                  line-height: get-line-height(16px, 20px);
                  margin-bottom: 0;
                  @media (min-width: $medium-max) {
                    font-size: 22px;
                  }
                }
                .icon {
                  width: 24px;
                  height: 24px;
                  position: absolute;
                  top: 10px;
                  #{$rdirection}: 0;
                  cursor: pointer;
                  background: none;
                  &::before,
                  &::after {
                    @include translate(-50%, -50%);
                    content: '';
                    background: $color-black;
                    display: block;
                    height: 2px;
                    width: 16px;
                    position: absolute;
                    #{$ldirection}: 50%;
                    top: 50%;
                  }
                  &::after {
                    width: 2px;
                    height: 16px;
                  }
                  &.icon--minus::after {
                    visibility: hidden;
                  }
                }
                .loyalty-find-out-more {
                  p {
                    margin: 0;
                  }
                  p,
                  a {
                    color: $color-black;
                    font-size: 14px;
                  }
                }
              }
              .viewcart-button {
                margin: 26px 0;
                .continue-shopping {
                  display: none;
                }
                .btn-primary {
                  width: 328px;
                  height: 48px;
                  font-size: 17px;
                  line-height: get-line-height(17px, 24px);
                  font-family: $helvetica-bold-font-family;
                  font-weight: bold;
                  padding: 12px 0;
                  text-transform: none;
                  background-color: $cr19-bg-black;
                  color: $cr19-text-white;
                  &:hover,
                  &.hover {
                    background-color: $cr19-bg-med;
                    color: $cr19-text-white;
                  }
                }
              }
              #recommended-products-panel {
                &.recommended-products-panel {
                  margin-bottom: 40px;
                  header {
                    text-align: center;
                    border: 0;
                    margin-top: 43px;
                    .recommended-products-panel__title {
                      font-size: 22px;
                      font-weight: bold;
                      line-height: get-line-height(22px, 20px);
                      text-transform: uppercase;
                      float: none;
                      font-family: $helvetica-bold-font-family;
                    }
                  }
                  .content {
                    padding-#{$ldirection}: 6px;
                    padding-#{$rdirection}: 6px;
                    .recommended-product-items {
                      display: flex;
                      flex-direction: row;
                      flex-wrap: nowrap;
                      justify-content: center;
                      align-items: stretch;
                      align-content: stretch;
                      position: relative;
                      .recommended-item {
                        text-align: center;
                        height: auto;
                        padding-bottom: 80px;
                        display: block;
                        position: static;
                        .product__image {
                          width: 200px;
                          height: 200px;
                          margin: 0 auto;
                          img {
                            height: 200px;
                          }
                        }
                        a {
                          float: none !important;
                        }
                        .description {
                          position: static;
                          margin-top: 15px;
                          float: none;
                          width: auto;
                          .product_name {
                            a {
                              font-size: 15px;
                              font-weight: bold !important;
                              opacity: 1;
                              color: $color-black !important;
                              font-family: $helvetica-bold-font-family !important;
                              letter-spacing: 0.25px;
                            }
                          }
                          .produce_subname {
                            display: none;
                          }
                          .shaded {
                            margin-left: 20px;
                            padding-left: 20px;
                          }
                        }
                        .formatted_price {
                          margin: 12px 0;
                          font-size: 16px;
                          font-family: $helvetica-bold-font-family;
                        }
                        .addtobag {
                          position: relative;
                          bottom: 0;
                          #{$ldirection}: inherit;
                          #{$rdirection}: inherit;
                          width: auto;
                          height: 80px;
                          padding: 0 20px;
                        }
                      }
                    }
                  }
                }
              }
              #favorites-pane {
                border-top: 2px solid $color-grey;
                border-bottom: 2px solid $color-grey;
                margin-bottom: 20px;
                display: block;
                @media (min-width: $medium-max) {
                  margin-bottom: 27px;
                }
              }
              #favorites-panel {
                margin-bottom: 0;
                header {
                  border: 0;
                  padding: 0 15px;
                }
                .checkout__panel-title {
                  font-size: 16px !important;
                  font-family: $helvetica-bold-font-family;
                  color: $color-black;
                  text-transform: uppercase;
                  line-height: get-line-height(16px, 20px);
                  float: none;
                  padding: 12px 0;
                  font-weight: bold;
                  @media (min-width: $medium-l) {
                    font-size: 22px !important;
                  }
                }
                .expand-toggle {
                  width: 24px;
                  height: 24px;
                  float: $rdirection;
                  position: relative;
                  background: none;
                  margin-top: -3px;
                  &::before,
                  &::after {
                    @include translate(-50%, -50%);
                    content: '';
                    background: $color-black;
                    display: block;
                    height: 2px;
                    width: 16px;
                    position: absolute;
                    #{$ldirection}: 50%;
                    top: 50%;
                  }
                  &::after {
                    width: 2px;
                    height: 16px;
                  }
                }
                .active {
                  .expand-toggle::after {
                    visibility: hidden;
                  }
                }
                .product {
                  border: 0;
                }
                .product-img {
                  width: 33.5%;
                  margin: 0;
                  max-width: none;
                  text-align: unset;
                  padding-#{$rdirection}: 10px;
                  float: $ldirection;
                  @media (min-width: $medium-l) {
                    padding-#{$rdirection}: 15px;
                    width: 28%;
                  }
                  @media (min-width: $medium-max) {
                    width: 27.5%;
                    text-align: center;
                  }
                  img {
                    width: auto;
                    background: $color-white;
                    min-width: 95px;
                  }
                }
                .product__desc {
                  width: 66.5%;
                  float: $ldirection;
                  margin-bottom: 12px;
                  margin-#{$ldirection}: 0;
                  @media (min-width: $medium-l) {
                    width: 72%;
                  }
                  @media (min-width: $medium-max) {
                    width: 72.5%;
                  }
                }
                .product-name {
                  font-size: 17px;
                  font-family: $helvetica-bold-font-family;
                  color: $color-black;
                  width: 86%;
                  a {
                    color: $color-black;
                    font-weight: bold;
                    font-family: $helvetica-bold-font-family;
                  }
                }
                .content {
                  padding: 0;
                  p {
                    @include swap_direction(margin, 0 0 12px 0);
                    font-size: 12px;
                    font-family: $helvetica-regular-font-family;
                    padding: 0 15px;
                  }
                  .product-list {
                    height: auto;
                  }
                  .product {
                    height: auto;
                    position: relative;
                    padding: 1px;
                    background: $color-lightest-grey-2;
                    margin: 16px;
                    @media (min-width: $medium-max) {
                      padding: 0;
                      background: none;
                      margin-top: 0;
                      margin-bottom: 0;
                    }
                  }
                }
                .product-subhead {
                  display: none;
                }
                .swatch {
                  width: 16px;
                  height: 16px;
                  border-radius: 50%;
                }
                .skus {
                  margin-top: 10px;
                  li {
                    margin-bottom: 10px;
                  }
                }
                .product-size,
                .shade {
                  font-size: 14px;
                  font-family: $helvetica-regular-font-family;
                  color: $color-black;
                  opacity: 0.64;
                }
                .price {
                  font-size: 16px;
                  font-family: $helvetica-regular-font-family;
                  margin-top: 10px;
                }
                .add-to-cart {
                  margin-top: 5px;
                  .btn {
                    font-size: 15px;
                    font-family: $helvetica-regular-font-family;
                    background: $color-black;
                    padding: 10px 8px;
                    color: $color-white;
                    border: 0;
                    height: auto;
                    @media (min-width: $medium-max) {
                      padding: 10px 25px;
                    }
                  }
                }
                .remove-action {
                  position: absolute;
                  #{$rdirection}: 0;
                  top: 7px;
                  .remove_link {
                    display: block;
                    margin-top: 0;
                    margin-#{$rdirection}: 5px;
                    font-size: 0;
                    @media (min-width: $medium-max) {
                      margin-top: 12px;
                      margin-#{$rdirection}: 2px;
                    }
                  }
                }
                footer {
                  display: none;
                }
              }
              .loyalty-offer-panel {
                border-top: 2px solid $color-grey;
                border-bottom: 2px solid $color-grey;
                margin-bottom: 20px;
                .loyalty-offer-section {
                  &__header {
                    border: 0;
                    padding: 0 15px;
                    &.active {
                      .expand-toggle {
                        &::after {
                          visibility: hidden;
                        }
                      }
                    }
                  }
                  &__title {
                    @include swap_direction(padding, 10px 20px 10px 0);
                    @media #{$medium-up} {
                      font-size: 22px !important;
                    }
                    font-size: 16px;
                    font-family: $helvetica-bold-font-family;
                    font-weight: bold;
                    line-height: get-line-height(16px, 2px);
                    text-transform: uppercase;
                    width: 100%;
                    position: relative;
                    .expand-toggle {
                      width: 24px;
                      height: 24px;
                      position: absolute;
                      #{$rdirection}: 0;
                      top: 5px;
                      background: none;
                      &::before,
                      &::after {
                        @include translate(-50%, -50%);
                        content: '';
                        background: $color-black;
                        display: block;
                        height: 2px;
                        width: 16px;
                        position: absolute;
                        #{$ldirection}: 50%;
                        top: 50%;
                      }
                      &::after {
                        width: 2px;
                        height: 16px;
                      }
                    }
                  }
                  &__content {
                    padding: 0;
                    .section-loyalty-offers {
                      border: 0;
                      .header-reward-benifits {
                        display: none;
                      }
                      .orders-text {
                        margin: 10px 8px;
                        font-size: 14px;
                      }
                      .section-box {
                        .content {
                          padding: 0 0 24px;
                        }
                      }
                      .checkout_offer_flexslider {
                        .flex-viewport {
                          max-height: auto;
                          .slider_list {
                            .offer-code-wallet {
                              &__item {
                                position: relative;
                                width: 100%;
                              }
                              &__code-info {
                                &--applied {
                                  @media #{$medium-up} {
                                    width: 40%;
                                    margin: 15px 45px 40px;
                                  }
                                  width: auto;
                                  margin: 15px 15px 30px;
                                }
                                &--content {
                                  @media #{$medium-up} {
                                    position: absolute;
                                    #{$ldirection}: 50%;
                                    top: 0;
                                    width: 40%;
                                    text-align: $ldirection;
                                    padding: 0;
                                  }
                                  padding: 0 15px;
                                  text-align: center;
                                  .offer_desc {
                                    font-family: $helvetica-bold-font-family;
                                    font-size: 16px;
                                    margin-bottom: 24px;
                                  }
                                  .offer_apply {
                                    margin-bottom: 24px;
                                    .btn-shopnow {
                                      margin: 0;
                                      padding: 15px 50px;
                                      height: auto;
                                      font-size: 14px;
                                      font-family: $helvetica-bold-font-family;
                                    }
                                  }
                                  .offer_count_text {
                                    font-size: 14px;
                                    .more_link {
                                      color: $color-black;
                                      font-size: 14px;
                                      text-decoration: underline;
                                      margin: 10px 0;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        .flex-control-nav {
                          @media #{$medium-up} {
                            top: 95%;
                          }
                          top: 100%;
                          li {
                            width: 10px;
                            height: 10px;
                            .active {
                              color: $cr19-text;
                            }
                          }
                        }
                        .flex-direction-nav {
                          display: block;
                          .flex-next,
                          .flex-prev {
                            @media #{$medium-up} {
                              top: 45%;
                            }
                            top: 30%;
                            width: 24px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &.pg_wrapper-full-width {
              float: none;
              width: 100%;
              text-align: center;
              @media (min-width: $medium-l) {
                max-width: 882px;
                margin: 0 auto;
              }
            }
            &.right {
              .panel {
                header {
                  border-top: 0;
                  &.active {
                    .expand-toggle::after {
                      visibility: hidden;
                    }
                  }
                  .expand-toggle {
                    position: relative;
                    height: 24px;
                    width: 24px;
                    background: none;
                    margin: 0;
                    &::before,
                    &::after {
                      @include translate(-50%, -50%);
                      content: '';
                      background: $color-black;
                      display: block;
                      height: 2px;
                      width: 16px;
                      position: absolute;
                      #{$ldirection}: 50%;
                      top: 50%;
                    }
                    &::after {
                      width: 2px;
                      height: 16px;
                    }
                  }
                  h2 {
                    font-size: 16px;
                    font-family: $helvetica-bold-font-family;
                    font-weight: bold;
                    line-height: get-line-height(16px, 20px);
                    text-transform: uppercase;
                    padding-#{$ldirection}: 0;
                    padding-#{$rdirection}: 0;
                  }
                }
              }
              .tab-switcher {
                @media (min-width: $medium-l) {
                  display: block;
                }
              }
              #offer-code-panel.panel {
                background: $color-white;
                padding: 8px 16px;
                border-bottom: 2px solid $color-gray-tier2;
                @media (min-width: $medium-l) {
                  padding-top: 16px;
                  padding-bottom: 16px;
                  border-bottom-width: 1px;
                }
                h2 {
                  margin-bottom: 0;
                }
                .content {
                  padding: 0;
                  p {
                    display: none;
                    &.note {
                      color: $color-cl-green;
                      border: 1px solid $color-cl-green;
                      padding: 5px;
                      display: block;
                    }
                  }
                  form {
                    &#offer_code {
                      margin-top: 15px;
                      position: relative;
                      .offer-code__input {
                        &.form-item {
                          width: 100%;
                          float: none;
                          padding-#{$rdirection}: 125px;
                          padding-top: 0;
                          @media (min-width: $medium-max) {
                            padding-#{$rdirection}: 110px;
                          }
                        }
                        label {
                          line-height: normal;
                          font-family: $helvetica-regular-font-family;
                          margin-top: -37px;
                          cursor: text;
                        }
                      }
                      .form--offer_code--field--OFFER_CODE {
                        width: 100%;
                        height: 56px;
                        line-height: normal;
                        @media (min-width: $medium-max) {
                          width: 227px;
                        }
                      }
                      .offer-code__submit {
                        position: absolute;
                        top: 0;
                        width: auto;
                        #{$rdirection}: 0;
                        .form-submit {
                          &.btn {
                            width: 108px;
                            height: 56px;
                            background: $color-black;
                            border: 0;
                            color: $color-white;
                            margin-top: 0;
                            font-size: 17px;
                            font-family: $helvetica-regular-font-family;
                            font-weight: bold;
                          }
                        }
                      }
                      .offer-code__see-link {
                        text-align: center;
                        padding: 30px 0 25px;
                      }
                      .continue-shopping {
                        border: 0;
                        text-decoration: underline;
                        color: $color-black;
                        font-size: 14px;
                        font-family: $helvetica-regular-font-family;
                        float: none;
                      }
                    }
                  }
                }
              }
            }
            #order-summary-panel {
              background: $color-white;
              padding: 5px 16px;
              border-bottom: 2px solid $color-gray-tier2;
              margin-top: 0;
              border-top: 2px solid $color-gray-tier2;
              @media (min-width: $medium-max) {
                border-top: 0;
                margin-top: 0;
                padding-top: 16px;
                padding-bottom: 16px;
              }
              header {
                @media (min-width: $medium-l) {
                  display: block;
                }
              }
              .order-summary-panel__title {
                width: 100%;
                span {
                  &.item-count__number {
                    display: none;
                  }
                }
              }
              .order-summary__content {
                .subtotal {
                  margin-top: 12px;
                }
                .value,
                .label {
                  padding-#{$ldirection}: 0;
                  opacity: 0.6;
                  font-size: 14px;
                  font-family: $helvetica-regular-font-family;
                  margin-bottom: 12px;
                  text-transform: capitalize;
                }
                .label {
                  text-transform: none;
                }
                .order-summary__tax-value {
                  display: none;
                }
                .value {
                  padding-#{$rdirection}: 0;
                  font-weight: bold;
                  color: $color-black;
                  opacity: 1;
                }
                .shipping-label-data {
                  display: block;
                  background-color: $cr19-bg-light;
                  padding: 15px 12px;
                  margin-top: 5px;
                  font-size: 12px;
                  border: 1px solid $color-shade-dark-grey;
                  color: $color-gray-dusty;
                  font-family: $helvetica-regular-font-family;
                  text-transform: capitalize;
                  .shipping-option {
                    font-style: italic;
                    margin-top: 10px;
                    font-family: $helvetica-regular-font-family;
                  }
                }
                .discount-save {
                  color: $color-gray-dusty;
                  font-family: $helvetica-regular-font-family;
                  font-size: 12px;
                  margin-top: 8px;
                  clear: both;
                }
                .ship-label-sec {
                  border-bottom: 1px solid $color-gray-tier2;
                  margin-bottom: 16px;
                  padding-bottom: 16px;
                }
                .total-label-sec {
                  border-top: 1px solid $color-gray-tier2;
                  margin-bottom: 16px;
                  padding-bottom: 16px;
                }
                .total-label-clear {
                  margin-top: 10px;
                }
                .total {
                  &.value,
                  &.label {
                    color: $color-black;
                    opacity: 1;
                    background: 0;
                    padding: 0;
                    margin-top: 0;
                  }
                }
                .content {
                  padding-#{$rdirection}: 0;
                  display: block;
                }
                #shipmethod-panel {
                  padding: 0;
                  border-bottom: 1px solid $color-gray-tier2;
                  margin-bottom: 20px;
                  #checkout_shipmethod {
                    .ship-method-group {
                      .form-item {
                        margin: 0;
                      }
                      label {
                        &.no-hide {
                          display: block;
                          color: $color-gray-dusty;
                          font-size: 12px;
                          font-family: $helvetica-regular-font-family;
                          line-height: get-line-height(12px, 20px);
                          border: 1px solid $color-gray-dusty;
                          background: $color-gray-tier1;
                          padding: 13px 12px 9px;
                        }
                      }
                    }
                  }
                }
              }
            }
            #links-panel {
              display: block;
              margin: 16px 0 50px;
              border-bottom: 2px solid $color-gray-tier2;
              border-top: 2px solid $color-gray-tier2;
              padding-top: 8px;
              padding-bottom: 8px;
              @media (min-width: $medium-l) {
                text-align: center;
                border-bottom: 0;
                border-top: 0;
                padding-top: 0;
                padding-bottom: 12px;
              }
              header {
                @media (min-width: $medium-l) {
                  pointer-events: none;
                  .expand-toggle {
                    display: none;
                  }
                }
                .links-panel__title {
                  padding-#{$ldirection}: 16px;
                  padding-#{$rdirection}: 16px;
                }
              }
              .content {
                @media (min-width: $medium-l) {
                  display: block !important;
                }
                .link {
                  margin-bottom: 20px;
                  &:first-child {
                    margin-top: 20px;
                  }
                  .links,
                  .overlay-link,
                  .helplinks,
                  .call-us {
                    font-size: 12px;
                    font-family: $helvetica-regular-font-family;
                    line-height: get-line-height(12px, 16px);
                    color: $color-black;
                    opacity: 0.88;
                    text-decoration: underline;
                    @media (min-width: $medium-l) {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
          .product {
            .remove_btn {
              &.remove_link {
                @media (min-width: $medium-l) {
                  font-size: 0;
                  background: url(/sites/clinique/themes/cl_base/img/icon-close.png) no-repeat 0 0
                    transparent;
                  width: 24px;
                  height: 24px;
                  background-size: 68%;
                  position: absolute;
                  top: 12px;
                  #{$rdirection}: 0;
                }
              }
            }
          }
        }
      }
      .pg_wrapper {
        max-width: 1280px;
        width: auto;
        margin-top: 0;
        position: relative;
        @media (min-width: $medium-max) {
          @include box-shadow(10px 0px 5px -11px $color-light-grey);
          margin: 35px auto 0;
        }
        &.cart-empty {
          @media (min-width: $medium-max) {
            @include box-shadow(none);
          }
        }
      }
      .checkout__sidebar {
        .checkout-btns {
          width: 100%;
        }
        .checkout__button {
          padding: 24px 16px 16px;
          display: none;
          @media (min-width: $medium-l) {
            display: block;
          }
        }
        .btn-primary {
          width: 100%;
          height: 48px;
          font-size: 17px;
          font-family: $helvetica-regular-font-family;
          font-weight: bold;
          padding: 12px;
          line-height: normal;
          text-transform: none;
          background-color: $cr19-bg-black;
          color: $cr19-text-white;
          &:hover,
          &.hover {
            background-color: $cr19-bg-med;
            color: $cr19-text-white;
          }
        }
      }
      .wrapper {
        min-height: calc(100% - 84px);
      }
      .sitewide-footer-formatter__bottom,
      #footer {
        margin-top: 0;
      }
      .block-template-appointment-booking-basic-responsive-v1 {
        @media (min-width: $medium-l) {
          margin: 0 auto;
        }
      }
      .back_to_top {
        display: none;
        font-size: 0;
        @media (min-width: $medium-l) {
          position: fixed;
          display: block;
          bottom: 40px;
          #{$rdirection}: 80px;
          background: $color-black;
          width: 45px;
          height: 45px;
          border-radius: 50%;
          cursor: pointer;
          z-index: 999;
          font-size: 14px;
          .icon-chevron-up {
            position: relative;
            top: 13px;
            font-family: $base-fontawesome-font-family;
            #{$ldirection}: 15px;
            color: $color-white;
            border: 0;
            &:before {
              font-size: 16px;
            }
          }
        }
      }
      .LPMcontainer,
      & ~ .LPMcontainer {
        display: none;
      }
    }
    .shipping-label-data {
      display: none;
    }
    body#viewcart {
      &.enhanced_cart_page {
        #main.single {
          .responsive-container {
            .column {
              &.left {
                #recommended-products-panel {
                  .content {
                    &.recommended-products-panel__content {
                      .recommended-product-items {
                        .addtobag {
                          .btn {
                            background-color: $color-black;
                            height: auto;
                            font-size: 16px;
                            line-height: get-line-height(16px, 19px);
                            font-weight: 700;
                            padding: 10px 20px;
                            text-transform: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
