.gnav-my-account-v1 {
  @media #{$xlarge-only} {
    height: 500px;
    overflow-y: scroll;
  }
  .social-login.gnav {
    font-size: 13px;
    .social-login__email-opt-in {
      padding-top: 20px;
      label {
        font-family: $base-font-family;
        font-color: $color-darkest-grey;
        padding-#{$ldirection}: 10px;
      }
    }
    .social-login__terms {
      margin-bottom: 10px;
    }
  }
}

.social-login {
  font-family: $base-font-family-roman;
  .social-login__email-opt-in {
    text-align: $ldirection;
    display: flex;
  }
  .social-login__terms {
    text-align: $ldirection;
  }
}

#main {
  .social-login__contents {
    padding: 0 10px;
    @media #{$medium-up} {
      padding: 0 15px;
    }
    #facebook_email_opt_in {
      width: 14px;
      height: 14px;
      border-radius: inherit;
      border: 1px solid $color-light-grey;
      margin-top: 3px;
      @media #{$medium-up} {
        width: auto;
        margin-top: 0;
        height: auto;
      }
    }
  }
}

.checkout__content {
  .confirmation-panel {
    .social-login {
      padding-#{$ldirection}: 13px;
      text-align: $ldirection;
      .social-login__email-opt-in {
        padding: 0;
      }
      .social-login__contents {
        @media #{$medium-up} {
          padding-#{$ldirection}: 0;
        }
      }
      h2.social-login__title {
        margin-bottom: 12px;
        font-size: 16px;
        color: $color-cl-soft-black;
        font-weight: bold;
        @media #{$medium-up} {
          font-weight: normal;
        }
      }
    }
  }
  .social-login {
    .social-login__email-opt-in {
      input[type='checkbox'] {
        width: 16px;
        height: 16px;
        z-index: 1;
      }
    }
  }
}

#main.single {
  .panel {
    .social-login {
      label {
        display: inline;
        padding-#{$ldirection}: 10px;
        color: $color-darkest-grey;
        font-family: $base-font-family-roman;
      }
      .social-login__terms {
        padding: 0;
      }
    }
  }
}

#signin {
  #content,
  #signin-block {
    .social-login {
      .social-login__email-opt-in {
        padding-#{$ldirection}: 0;
        input[type='checkbox'] {
          width: 16px;
          height: 16px;
          @media #{$medium-up} {
            width: auto;
            height: auto;
          }
        }
        .social-login__opt-in-checkbox {
          margin-top: 3px;
          width: 27px;
        }
        label {
          cursor: text;
          color: $color-darkest-grey;
          padding-top: 0;
          @media #{$medium-up} {
            padding-top: 2px;
          }
        }
      }
      .social-login__terms {
        padding: 0;
        text-align: $ldirection;
      }
    }
  }
}

.profile-form-container {
  #fedid-fields {
    padding: 0;
    border: none;
    .identification-type {
      display: inline-block;
    }
  }
  .identification-type {
    display: inline-block;
  }
}

#signin-block.login-email {
  .social-login {
    .social-login__contents {
      @media #{$medium-up} {
        padding-#{$ldirection}: 0;
      }
    }
  }
}

.checkout-confirmation-page {
  .social-login {
    .social-login__container {
      text-align: $ldirection;
    }
  }
}
