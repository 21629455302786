.loyalty-popover {
  &__cpf {
    display: inline;
  }
  &__full-name {
    margin: 10px 0;
  }
  &__offer-cpf {
    margin-bottom: 10px;
  }
}
.cpf-radio {
  display: none;
}
.welcome-offer-form-node {
  .form-submit-wrap {
    padding-bottom: 10px;
  }
}
#gnav_signin {
  .signin_container {
    .form {
      .row,
      .password-field {
        margin-bottom: 10px;
      }
      .privacy-form-container {
        margin-bottom: 0;
      }
    }
    .myaccount-hdr {
      margin-bottom: 15px;
    }
    .loyalty {
      &__block {
        .row {
          margin-top: 10px;
        }
      }
    }
    .social-login {
      &__email-opt-in-label {
        margin-top: 0;
      }
    }
  }
  .signin_submit {
    margin-top: 0;
  }
}
//Loyalty signin popup
#cboxWrapper {
  #cboxContent {
    .loyalty_popover {
      .loyalty_popover {
        &__content {
          &.loyalty_popover__welcome {
            padding-top: 30px;
            @media #{$medium-up} {
              padding-top: 80px;
            }
            .loyalty_popover__password {
              .form-password {
                width: 60%;
                border: 2px solid $color-light-grey;
                float: $ldirection;
                @media #{$medium-up} {
                  width: 330px;
                  height: 64px;
                }
              }
              .form-submit {
                width: 30%;
                margin: 0 10px;
                height: 40px;
                @media #{$medium-up} {
                  height: 64px;
                }
              }
            }
            .loyalty_popover__buttons {
              .button {
                height: 50px;
                line-height: 50px;
                padding: 0px 30px;
                @media #{$medium-up} {
                  height: 64px;
                  line-height: 64px;
                }
              }
              .button--dark {
                margin-#{$ldirection}: 0;
              }
            }
          }
          .background-img {
            position: absolute;
            top: 0;
            #{$rdirection}: 0;
            width: 45%;
          }
          .loyalty_popover {
            &__logo {
              @include swap_direction(margin, 5px 0 0 18px);
              img {
                position: absolute;
                top: 15px;
                #{$ldirection}: 20px;
                @media #{$medium-up} {
                  #{$ldirection}: 50px;
                }
              }
            }
            &__header {
              &.title--large {
                letter-spacing: -1.5px;
                font-size: 28px;
                position: relative;
                width: 95%;
                margin: 25px 0;
                line-height: 1.2;
                max-width: 375px;
                @media #{$medium-up} {
                  font-size: 50px;
                  margin: 15px 0;
                  width: 100%;
                  line-height: 1;
                  max-width: 100%;
                }
              }
            }
            &__input {
              .loyalty_popover {
                &__checkbox-container {
                  width: 95%;
                  @media #{$medium-up} {
                    @include swap_direction(margin, 10px 0 0 0);
                    top: 5px;
                    width: 450px;
                    #{$ldirection}: 3%;
                  }
                  .loyalty_popover {
                    &__checkbox-label {
                      font-size: 13px;
                      line-height: 1.1;
                      width: 95%;
                    }
                  }
                }
              }
            }
            &__sub-header {
              @media #{$medium-up} {
                @include swap_direction(margin, 7px 0 10px 18px);
                font-size: 17px;
              }
            }
            &__submit-container {
              margin-bottom: 5px;
              .form-text {
                border: 2px solid $color-light-grey;
                width: 60%;
                height: 40px;
                @media #{$medium-up} {
                  margin: 0;
                  border: 2px solid $color-light-grey;
                  width: 100%;
                  height: 60px;
                  position: relative;
                }
              }
              .form-submit {
                width: 150px;
                height: 40px;
                margin-#{$ldirection}: 2px;
                @media #{$medium-up} {
                  margin: 0;
                  height: 50px;
                  width: 100%;
                  min-width: 280px;
                }
              }
            }
          }
        }
      }
    }
  }
}
//Checkout Loyalty Slider
.section-loyalty-offers {
  border-top: 1px solid $color-shade-grey;
  .checkout_offer_flexslider {
    position: relative;
    .flex-viewport {
      max-height: inherit;
      .slides {
        .slider-list {
          margin-#{$rdirection}: 0px;
          .offer-code-wallet {
            &__item {
              .offer-code-wallet {
                &__code-info--applied {
                  margin: 15px 35px 40px;
                  min-height: 230px;
                }
              }
              .offer {
                &_apply {
                  display: block;
                  margin-bottom: 10px;
                  .btn-shopnow.not_meet {
                    background: $color-dark-grey;
                    cursor: default;
                  }
                }
                &_title {
                  font-size: 20px;
                  line-height: 40px;
                }
                &_name {
                  font-size: 11px;
                  color: $color-shade-dark-grey;
                  .btn-shopnow {
                    @include swap_direction(margin, 20px 0 0 0);
                    width: 127px;
                    height: 27px;
                    line-height: 0;
                    font-size: 13px;
                  }
                }
              }
              .offer-code-wrapper {
                margin: 0 0 10px;
                display: block;
              }
              .more_link {
                margin: 15px 0;
                font-size: 17px;
                display: block;
              }
            }
          }
          &.not_meet {
            .offer-code-wallet {
              &__code-info--applied {
                img {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
    .flex-control-paging {
      position: absolute;
      bottom: 30%;
      text-align: center;
      width: 100%;
    }
    .flex-direction-nav {
      li a {
        width: 7px;
        top: 25%;
        opacity: 1;
        &.flex-prev {
          #{$ldirection}: 0px;
        }
        &.flex-next {
          #{$rdirection}: 0px;
        }
        &.flex-disabled {
          display: none;
        }
      }
    }
  }
  .section-header {
    font-size: 22px;
    height: auto;
    padding: 10px 0 0;
    color: $color-black;
    margin: 0;
    font-weight: 400;
  }
}

.checkout__sidebar {
  .loyalty__points__description {
    margin-top: 40px;
    padding: 10px 0;
    border-top: 1px solid $color-shade-grey;
  }
}
//Loyalty Order confirm page
#main.single {
  .loyalty-enrollment {
    border-top: 1px solid $color-shade-grey;
    margin-top: 30px;
    background: url(/media/export/cms/SmartRewardsAssets.jpg) right 0 no-repeat;
    background-size: 300px 200px;
    @media #{$medium-up} {
      background-size: 280px 400px;
    }
    #info {
      margin-top: 30%;
      @media #{$medium-up} {
        margin-top: 0;
      }
    }
  }
  #confirmation-page {
    #confirmation-panel {
      .confirmation-panel__loyalty {
        background: url(/media/export/cms/SmartRewardsAssets.jpg) right 0 no-repeat;
        background-size: 300px 200px;
        @media #{$medium-up} {
          background-size: 300px 325px;
          display: inline-block;
          min-height: 210px;
          margin: 10% 0 5%;
        }
        &-header {
          font-size: 18px;
          font-weight: bold;
          font-family: inherit;
          @media #{$medium-up} {
            font-size: 22px;
          }
        }
        #loyalty-points-confirm {
          font-size: 15px;
          font-family: $base-font-family;
          font-weight: bold;
          @media #{$medium-up} {
            width: 48%;
            font-size: 20px;
          }
        }
        #info {
          margin-top: 28%;
          display: inline-block;
          @media #{$medium-up} {
            margin-top: 0;
          }
        }
      }
      .loyalty-header {
        margin: 20px 0;
        font-size: 18px;
        letter-spacing: 0.3px;
        font-family: inherit;
        font-weight: $font-weight-bold;
        width: 100%;
        @media #{$medium-up} {
          width: 54%;
          font-size: 21px;
        }
      }
      .loyalty-text {
        font-size: 16px;
        font-family: inherit;
        @media #{$medium-up} {
          width: 56%;
          font-size: 18px;
        }
      }
      .loyalty-points {
        font-size: 18px;
        letter-spacing: 0.3px;
        font-family: inherit;
        font-weight: bold;
        margin-top: 7%;
        display: inline-block;
        margin-bottom: 0;
        @media #{$medium-up} {
          width: 46%;
          font-size: 21px;
        }
      }
      .submit_btn {
        .form-submit {
          display: inline-block;
          font-size: 15px;
          font-family: inherit;
          padding: 5px 25px;
          font-weight: $font-weight-bold;
          letter-spacing: 1px;
          width: auto;
          background: $color-black;
          height: auto;
          line-height: 2;
        }
      }
    }
    .sign-in-confirmation-panel {
      .h_loyalty {
        width: 100%;
        color: $color-notice-pink;
        font-size: 25px;
        font-weight: $font-weight-bold;
        margin: 20px 0;
        @media #{$cr19-medium-up} {
          width: 54%;
        }
      }
    }
  }
  .loyalty-highlight {
    font-weight: $font-weight-normal;
    font-size: 16px;
    @media #{$medium-up} {
      font-weight: $font-weight-bold;
    }
  }
  .loyalty_list {
    padding: 15px 0 25px;
    font-size: 18px;
    font-family: $base-font-family;
    color: $color-black;
    width: 100%;
    @media #{$medium-up} {
      width: 55%;
    }
    .error_messages {
      padding: 15px 0 25px;
      width: 55%;
    }
  }
  .loyalty-promo {
    margin-top: 20px;
    font-size: 12px;
    @media #{$medium-up} {
      margin-top: 60px;
    }
  }
  .loyalty-offer-pane {
    .smartrewards-panel {
      &__title {
        font-size: 24px;
        padding: 14px 0 5px;
        line-height: 1.2em;
        margin-bottom: 0;
      }
    }
    .btn-shopnow {
      padding: 0 10px;
    }
    .wrapper & {
      #viewcart & {
        .js & {
          height: auto;
          opacity: 1;
          @media #{$medium-up} {
            opacity: 0;
            height: 0;
          }
        }
      }
    }
  }
  #viewcart & {
    .js & {
      .right {
        &.checkout {
          &__sidebar {
            #offer-code-panel {
              @media #{$medium-up} {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
}
//Smartrewards page
.loyalty-marketing-page {
  .loyalty_mrkt_ldng {
    .loyalty-sticky-nav {
      .loyalty {
        &__join-now {
          @media #{$large-up} {
            @include swap_direction(padding, 0 0 0 90px);
          }
          .join-now-btn {
            @media #{$large-up} {
              padding: 14px 15px;
            }
            @media #{$small-only} {
              font-size: 13px;
              padding: 17px 10px;
            }
          }
        }
        &__signoff {
          @media #{$large-up} {
            @include swap_direction(padding, 20px 0 0 90px);
          }
        }
      }
    }
    .loyalty_market_sticky_nav {
      @media #{$medium-up} {
        padding: 30px;
      }
      @media #{$cr19-large-up} {
        padding: 32px 72px;
      }
    }
    .loyalty_market_points {
      border-bottom: 0;
      table {
        td.points_even {
          background: transparent;
        }
      }
    }
    .content-block__line--eyebrow {
      margin-top: 150px;
    }
  }
}
#landing {
  &.is-loyalty-member {
    #loyalty-status {
      &__content {
        #loyalty {
          &__page {
            &__status {
              .loyalty-panel-offers-sku {
                &__cta {
                  a.button {
                    line-height: 1.2;
                    padding: 5px 10px;
                  }
                }
                &__product-wrapper {
                  min-height: 110px;
                }
              }
              .loyalty_status_offers_sku {
                @media #{$portrait} {
                  .flex-next {
                    right: -20px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .account_landing_wrapper {
      .best-sellers-loyalty-wrapper {
        .prod-shelf-row {
          .btn-shopnow {
            position: absolute;
            bottom: 0;
            width: auto;
            max-width: 100%;
          }
        }
      }
      @media #{$portrait} {
        #loyalty__panel__offers__earnpoints {
          top: 830px;
        }
        .next-tier-points-msg {
          margin-#{$ldirection}: 45px;
        }
      }
      .account {
        &.breadcrumb {
          @media #{$portrait} {
            padding-bottom: 133%;
          }
          @media #{$cr19-medium-landscape-only} {
            padding-bottom: 98%;
          }
        }
      }
    }
  }
  .best-sellers {
    padding-top: 10%;
  }
}
.js {
  body {
    &.loyalty_offer_signup {
      #colorbox {
        height: 510px !important;
        #cboxWrapper {
          border: none;
          @media #{$medium-up} {
            height: 440px !important;
          }
          #cboxContent {
            #cboxLoadedContent {
              height: 450px !important;
              padding: 0 20px;
              @media #{$medium-up} {
                height: 368px !important;
              }
            }
          }
        }
      }
    }
    &#registration {
      #foreground-node {
        .loyalty_cancellation_popup {
          .loyalty_popover {
            &__buttons {
              .cancel_loyalty_submit {
                padding: 0 11px;
                @media #{$medium-up} {
                  padding: 5px 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.account-header-panel {
  ul {
    &.shortcuts {
      @media #{$medium-up} {
        width: 70%;
      }
    }
  }
}
.device-mobile {
  .account-header-panel {
    &.panel {
      display: block;
      ul {
        &.shortcuts {
          width: 100%;
          li {
            width: 50%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
#loyalty__page__status {
  .loyalty_status_point {
    .account-loyalty {
      .loyalty__panel__points {
        &__bg__slide-container {
          display: flex;
          .loyalty__panel__points {
            &__bg__Bar-container {
              width: 25%;
              .arrow-down-smart {
                border-#{$ldirection}: 7px solid $color-ly-dark-green;
                border-#{$rdirection}: 7px solid $color-ly-dark-green;
                @media #{$cr19-medium-up} {
                  border-#{$ldirection}: 5px solid $color-ly-dark-green;
                  border-#{$rdirection}: 5px solid $color-ly-dark-green;
                }
              }
              .arrow-down-brilliant {
                border-#{$ldirection}: 7px solid $color-ly-dark-purple;
                border-#{$rdirection}: 7px solid $color-ly-dark-purple;
                @media #{$cr19-medium-up} {
                  border-#{$ldirection}: 5px solid $color-ly-dark-purple;
                  border-#{$rdirection}: 5px solid $color-ly-dark-purple;
                }
              }
              .arrow-down-genius {
                border-#{$ldirection}: 7px solid $color-ly-dark-orange;
                border-#{$rdirection}: 7px solid $color-ly-dark-orange;
                @media #{$cr19-medium-up} {
                  border-#{$ldirection}: 5px solid $color-ly-dark-orange;
                  border-#{$rdirection}: 5px solid $color-ly-dark-orange;
                }
              }
            }
          }
        }
      }
    }
  }
}
body {
  &.loyalty_offer_signup {
    &.domain-br {
      #colorbox {
        @media #{$medium-up} {
          width: 415px !important;
          height: 435px !important;
        }
        #cboxWrapper {
          padding: 0;
          @media #{$medium-up} {
            width: unset !important;
            height: 435px !important;
          }
          #cboxContent {
            @media #{$medium-up} {
              width: 355px !important;
              height: 100% !important;
              padding: 45px 25px 0;
            }
            #cboxLoadedContent {
              padding: 0 20px;
              @media #{$medium-up} {
                width: 100% !important;
                height: 100% !important;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
.account-loyalty {
  .section-content {
    .star-points {
      font-weight: bold;
    }
    .loyalty__panel__points {
      &__bg__slide-container {
        .loyalty__panel__points {
          &__bg__Bar-container {
            @media #{$medium-up} {
              width: 30%;
            }
          }
        }
      }
    }
  }
}
#loyalty__page__history {
  .loyalty__panel__points__progessbar {
    width: 42%;
  }
}
#loyalty__page__about,
.loyalty-marketing-page {
  .loyalty_mrkt_ldng {
    .loyalty_market_points {
      border: none;
      &_right {
        text-align: $rdirection;
      }
      table {
        font-size: 16px;
        td {
          padding: 15px 0;
        }
      }
    }
    .content-block__line--eyebrow {
      position: absolute;
      top: 25%;
      width: 60%;
      margin-top: 0;
      @media #{$portrait} {
        display: none;
      }
    }
    .content-block__line--headline {
      .title--large {
        p {
          font-size: 45px;
          @media #{$portrait} {
            font-size: 32px;
          }
          @media #{$cr19-medium-landscape-only} {
            font-size: 30px;
          }
        }
      }
    }
    @media #{$portrait} {
      .loyalty-grid-formatter__description {
        display: none;
      }
      .editorial-grid-formatter__carousel {
        &-controls {
          .previous {
            left: 10px;
          }
          .next {
            right: 10px;
          }
        }
      }
      .split-width-text-tout {
        padding: 20px 20px 10px;
      }
    }
  }
}
#loyalty__panel__benefits__content,
#loyalty__page__history,
#loyalty__panel__smart-rewards,
.loyalty-marketing-page {
  .loyalty__panel__tier {
    li {
      list-style-type: none;
      margin-bottom: 8px;
      text-indent: -16px;
      &:before {
        width: 2px;
        height: 2px;
        display: inline-block;
        content: '';
        background-color: $color-black;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        margin-#{$rdirection}: 15px;
        vertical-align: middle;
      }
    }
  }
  .benefits_flexslider {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 24px;
    &__warning {
      font-size: 13px;
    }
  }
}
#landing,
#loyalty__page__index {
  #loyalty__panel__offers__sku {
    .loyalty__panel__offers__sku__list-container {
      .offer_sku_flexslider {
        .offer_not_met {
          .loyalty__panel__offers__sku__cta {
            .button {
              background-color: $color-btn-gray;
              pointer-events: none;
            }
          }
        }
        .button {
          font-size: 13px;
          font-family: inherit;
          padding-top: 16px;
        }
      }
    }
  }
  #loyalty__panel__benefits__content {
    .loyalty__panel__tier {
      h2 {
        font-family: inherit;
      }
    }
  }
}
#landing,
#loyalty__page__index,
#loyalty__page__history,
#loyalty__panel__best-sellers_list {
  .loyalty__panel {
    &__subtitle {
      font-family: inherit;
      font-size: 16px !important;
    }
    &__title,
    &__points__title {
      font-family: inherit;
    }
    &__points__title {
      margin-top: 10px;
    }
    &__points__points__details {
      .loyalty__panel__transactions__title {
        font-family: inherit;
        margin-bottom: 0 !important;
      }
    }
  }
  .best-sellers-loyalty-wrapper {
    h2 {
      font-family: inherit;
    }
  }
  @media #{$portrait} {
    .loyalty__panel__points {
      .next-tier-points-msg {
        margin-#{$ldirection}: 45px;
      }
    }
    #loyalty__panel__offers {
      .loyalty__panel__offers__list-container,
      .slides {
        padding: 0 15px;
      }
    }
    #loyalty__panel__perks {
      padding: 0 20px;
      #points_perks {
        width: 100%;
      }
    }
    #loyalty__panel__transactions {
      .loyalty__panel__points__points__details {
        width: 50%;
        float: $rdirection;
      }
      .next-tier-points-msg {
        margin-#{$ldirection}: 45px;
      }
    }
  }
}
.account-section {
  &.loyalty-page {
    #loyalty__panel__best-sellers_list {
      .loyalty__panel__best-sellers__list-container {
        @media #{$portrait} {
          padding: 15px 20px !important;
        }
        .product-list {
          .button {
            font-family: inherit;
            font-size: 13px;
            padding: 15px 25px;
            width: 100%;
          }
        }
        #loyalty__panel__best-seller-info {
          .loyalty__panel__best-seller-info {
            &_tittle-1 {
              font-family: inherit !important;
              font-weight: bold;
              font-size: 27px;
            }
          }
        }
      }
    }
  }
}
#page-header-wrapper {
  .header-gnav__wrapper {
    .gnav-header-formatter__wrapper {
      .header-gnav-account-link {
        &__loyalty-link {
          display: flex;
          flex-direction: row-reverse;
          span {
            padding-#{$ldirection}: 8px;
          }
        }
      }
    }
  }
}
.loyalty_popover__checkbox-container {
  @media #{$medium-up} {
    top: unset !important;
    bottom: unset !important;
    left: unset !important;
    width: 100% !important;
  }
}
.loyalty_popover__promo > h4 {
  margin: 15px 0 !important;
}
