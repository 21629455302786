// SPC Checkout Styles
@mixin spc_checkout_styles {
  .checkout {
    .loading {
      background: url(/media/export/cms/479-01Clinique_LoadingGif_R22.gif) no-repeat center center;
      * {
        opacity: 0.8;
        filter: alpha(opacity=80);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
      }
    }
  }
  body {
    &.toolbar-drawer {
      &.device-mobile {
        @media (max-width: 48em) {
          padding-top: 0;
        }
      }
    }
  }
  /* RECOMMEDED PRODUCTS PANEL FROM CL BASE */
  body#viewcart #recommended-products-panel {
    .recommended-product-items .recommended-item {
      display: table;
      width: 33%;
      float: $ldirection;
      height: 170px;
      a {
        float: $ldirection;
      }
      .product_name a {
        @include cl-standard-link($color-text-black, false, $color-cl-green-2-darker);
      }
      .description {
        float: $ldirection;
        width: 105px;
      }
      .addtobag,
      .quickshop {
        clear: $ldirection;
      }
      .addtobag {
        clear: none;
        input[type='submit'] {
          @include a-button-base;
        }
      }
    }
  }
  /* CHECKOUT PAGE VIEWCART PANEL FROM CL BASE */
  body#index #main.single {
    .item-count {
      float: $ldirection;
    }
    .panel.edit {
      header,
      h2 {
        background-color: $color-m-footer;
      }
    }
    #viewcart-panel {
      border-bottom-width: 0;
      margin-bottom: 0;
      display: block;
      .viewcart-buttons-panel {
        display: none;
      }
      .content {
        display: block;
        padding-bottom: 0;
      }
    }
    .cart-header {
      display: none;
    }
    #order-summary-panel {
      header h2 {
        float: $ldirection;
      }
      .label {
        text-align: $ldirection;
      }
    }
    .product {
      &.shaded {
        .responsive-shade-shim {
          height: 2.4em;
          clear: both;
        }
        .color {
          position: absolute;
          bottom: 1em;
          #{$ldirection}: 0;
          width: 100%;
          .swatch {
            position: absolute;
            top: 0;
            #{$ldirection}: 0;
          }
          .shade {
            position: absolute;
            top: 0;
            #{$ldirection}: 125px;
          }
        }
      }
      .price {
        width: 100%;
      }
      .qty {
        min-width: 4em;
        width: 40%;
        margin-top: 0;
        float: $rdirection;
        text-align: $rdirection;
        padding-#{$rdirection}: 0;
      }
      .qty-label {
        display: inline-block;
      }
      .total {
        text-align: $rdirection;
        width: 20%;
        float: $rdirection;
      }
      .thumb {
        width: 40%;
        img {
          width: 100%;
        }
      }
      .desc {
        width: 40%;
      }
    }
  }
  #main {
    .mobile-only {
      display: none;
      @media (max-width: 48em) {
        display: block;
      }
    }
    .mobile_hidden {
      display: block;
      @media (max-width: 48em) {
        display: none;
      }
    }
    &.single {
      clear: both;
      .loading {
        background: url(/media/export/cms/479-01Clinique_LoadingGif_R22.gif) no-repeat center center;
        * {
          opacity: 0.8;
          filter: alpha(opacity=80);
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
        }
      }
      .responsive-container {
        #mobile-breadcrumb {
          @media (max-width: 48em) {
            display: none;
          }
        }
        .float-left {
          float: $ldirection;
        }
        .float-right {
          float: $rdirection;
        }
        #error_checkout.please_signin {
          display: none;
        }
        fieldset {
          border: 0;
          padding: 0;
        }
        .messages {
          .single-message {
            color: $color-notice-pink;
            border: 0;
            background: none;
          }
        }
        #recommended-products-panel {
          .recommended-product-items {
            .recommended-item {
              position: relative;
              .formatted_price {
                @media (max-width: 48em) {
                  margin-top: 10px;
                }
              }
              .addtobag {
                position: absolute;
                bottom: 0;
                #{$ldirection}: 0;
                #{$rdirection}: 0;
                text-align: center;
                input[type='submit'] {
                  @media (max-width: 48em) {
                    width: 90%;
                    color: $color-white;
                    padding: 5px 10px;
                    font-weight: bold;
                    text-align: center;
                    border: 0;
                    white-space: normal;
                    height: auto;
                    line-height: 1.5;
                    font-size: 80%;
                    @if $cr19 == true {
                      background-color: $cr19-bg-black;
                    } @else {
                      background-color: $color-cl-green-2;
                    }
                  }
                }
              }
              @media (max-width: 48em) {
                float: $ldirection;
                width: 33%;
                padding: 0 10px;
                text-align: center;
                height: 320px;
              }
              @media (min-width: 40.0625em) {
                .description {
                  position: absolute;
                  bottom: 60px;
                  #{$ldirection}: 70px;
                  width: 100px;
                }
              }
            }
          }
        }
        h3 {
          &.checkout__subtitle {
            margin: 15px 0 0 0;
            font-size: 2em;
            @media (max-width: 48em) {
              font-size: 16px;
            }
          }
          @media (max-width: 48em) {
            font-size: 16px;
          }
        }
        .form-item {
          &.phone-1 {
            @media (max-width: 48em) {
              clear: both;
            }
          }
        }
        .samples-panel,
        .favorites-panel {
          .add-button,
          .no-thanks {
            @media (max-width: 48em) {
              display: none;
            }
          }
          .product-name {
            @media (max-width: 48em) {
              font-size: 16px;
              margin-top: 10px;
            }
          }
          .product-img {
            @media (max-width: 48em) {
              width: 45%;
              float: $ldirection;
              text-align: center;
              margin-#{$rdirection}: 1em;
            }
          }
          .product {
            @media (max-width: 48em) {
              border-bottom: 1px solid $color-shade-grey;
              margin-top: 10px;
              padding-bottom: 10px;
            }
          }
        }
        #sign-in-panel {
          .checkout__subtitle {
            font-size: 1.3em;
          }
        }
        .nojs_hidden,
        .default-shipping {
          margin: 0;
        }
        .clearfix {
          &:after {
            visibility: hidden;
            height: 0;
            content: '';
            display: table;
            clear: both;
          }
        }
        .green {
          color: $color-cl-green;
        }
        #shipmethod-panel {
          select {
            width: 100%;
          }
          #shipmethod-panel {
            section {
              margin-bottom: 0;
            }
          }
          #checkout_shipmethod {
            .ship-method-group {
              label {
                display: none;
                &.no-hide {
                  display: none;
                }
              }
            }
            .ship-method-group-label {
              float: $ldirection;
              width: 100%;
              @media #{$small-only} {
                margin-bottom: 5px;
              }
              input[type='radio'] {
                clear: $ldirection;
                margin-top: 5px;
                margin-#{$ldirection}: 0;
                margin-bottom: 10px;
                height: auto;
              }
              label {
                margin-top: 4px;
                a.more-info {
                  float: none;
                }
              }
            }
          }
        }
        .column {
          &.left {
            #order-summary-panel {
              display: none;
              .content {
                @media (max-width: 48em) {
                  padding: 0 1em;
                }
              }
              .label {
                @media (max-width: 48em) {
                  width: 70%;
                }
                &.total {
                  @media (max-width: 48em) {
                    font-weight: bold;
                    background-color: $color-c1-white;
                  }
                }
              }
              .value {
                &.total {
                  @media (max-width: 48em) {
                    font-weight: bold;
                    background-color: $color-c1-white;
                  }
                }
              }
              @media (max-width: 48em) {
                display: block;
                padding: 1.5em 0;
              }
            }
            .recommended-products-panel {
              header {
                @media (max-width: 48em) {
                  border-top: 1px solid $color-c1-white;
                }
              }
            }
            #registration-panel {
              .how-will-we-use {
                float: none;
                border: 0;
                width: auto;
                padding: 0;
              }
            }
          }
          &.right {
            .panel {
              header {
                h2 {
                  font-size: 26px;
                  width: 100%;
                  .expand-toggle {
                    margin-top: 10px;
                  }
                }
                @media (max-width: 48em) {
                  border-top: 1px solid $color-c1-white;
                }
              }
              .content {
                padding: 0 1em 1em;
              }
              .item-count__number {
                @media #{$medium-up} {
                  display: none;
                }
              }
            }
            #viewcart-shopping-bag-panel {
              .product {
                .desc {
                  width: 60%;
                }
                .qty {
                  text-align: $ldirection;
                  float: $ldirection;
                }
                .total {
                  text-align: $ldirection;
                  width: 60%;
                }
              }
              .myog_edit_link {
                display: none;
              }
              .myog-remove {
                display: none;
              }
              .product_subname_myog {
                position: relative;
                #{$ldirection}: 41%;
                bottom: 70px;
                width: 40%;
                @media (max-width: 48em) {
                  #{$ldirection}: 44%;
                }
              }
              .cart-item__qty_myog {
                width: 20%;
                position: relative;
                #{$ldirection}: 41%;
                bottom: 52px;
                @media (max-width: 48em) {
                  width: 65%;
                  #{$ldirection}: 4%;
                  bottom: 10px;
                }
              }
              .cart-item__price_myog {
                position: relative;
                #{$ldirection}: 41%;
                bottom: 65px;
                @media (max-width: 48em) {
                  bottom: 65px;
                  #{$ldirection}: 44%;
                }
              }
              .cart-item__total_myog {
                position: relative;
                #{$ldirection}: 41%;
                bottom: 35px;
                font-weight: bold;
                @media (max-width: 48em) {
                  #{$ldirection}: 44%;
                }
              }
              .continue-checkout-bottom {
                @media (max-width: 48em) {
                  display: none;
                }
              }
            }
            .tab-bar {
              display: none;
            }
            #samples-pane {
              margin-top: 0;
            }
            #samples-panel,
            #favorites-panel {
              .available,
              .max {
                margin-#{$ldirection}: 1em;
                margin-top: 0;
              }
              footer {
                display: none;
              }
              .sample-select-button {
                color: $color-white;
                line-height: 1.5;
                padding: 5px 10px;
                font-weight: bold;
                display: block;
                text-align: center;
                @if $cr19 == true {
                  background-color: $cr19-bg-black;
                } @else {
                  background-color: $color-cl-green-2;
                }
              }
              .product-size {
                display: none;
              }
              .details {
                float: $ldirection;
                width: 50%;
                margin-#{$ldirection}: 0;
              }
              ul.skus {
                margin-top: 5px;
              }
              .product-list {
                overflow: auto;
                max-height: 500px;
                .product {
                  margin: 0;
                  min-height: 160px;
                  &.selected {
                    background: $color-c1-white;
                  }
                }
                .product-name {
                  float: none;
                  width: 100%;
                }
                .deactivate {
                  * {
                    cursor: default;
                    color: $color-light-grey;
                  }
                }
              }
            }
            #favorites-panel {
              ul.product-list {
                .product {
                  height: auto;
                  @media (max-width: 48em) {
                    height: auto;
                  }
                }
                .product-img {
                  img {
                    width: auto;
                  }
                }
              }
              .add-to-cart {
                input[type='submit'] {
                  color: $color-white;
                  line-height: 1.5;
                  padding: 5px 10px;
                  font-weight: bold;
                  border: 0;
                  text-transform: uppercase;
                  height: auto;
                  @if $cr19 == true {
                    background-color: $cr19-bg-black;
                  } @else {
                    background-color: $color-cl-green-2;
                  }
                }
              }
            }
            #offer-code-panel {
              p {
                @media (max-width: 48em) {
                  margin-bottom: 1em;
                }
              }
              input[type='text'] {
                width: 100%;
              }
              #offer_code {
                .offer-code__submit {
                  text-align: center;
                  .form-submit {
                    @media (max-width: 48em) {
                      background: $color-white;
                      height: 37px;
                      margin-top: 5px;
                      line-height: get-line-height(17px, 37px);
                      padding: 0;
                      @if $cr19 == true {
                        border: 1px solid $cr19-border-black;
                        color: $cr19-text-black;
                      } @else {
                        border: 1px solid $color-cl-green-2;
                        color: $color-cl-green-2;
                      }
                    }
                  }
                  @media (max-width: 48em) {
                    float: $rdirection;
                    width: 40%;
                  }
                }
                .continue-shopping {
                  @media (max-width: 48em) {
                    border: none;
                    font-size: 12px;
                    height: 30px;
                    line-height: get-line-height(14px, 21px);
                    margin: 5px 0 0 0;
                    padding: 0;
                    max-width: 100%;
                    text-align: $rdirection;
                    float: $rdirection;
                  }
                }
                .offer-code__input {
                  @media (max-width: 48em) {
                    float: $ldirection;
                    margin: 0;
                    width: 57%;
                    height: 45px;
                  }
                }
              }
            }
            .customer-service-content {
              max-width: 90%;
            }
          }
          .payment-type {
            label {
              .text-label {
                @media (max-width: 48em) {
                  margin-top: 0;
                }
              }
              @media (max-width: 48em) {
                float: $ldirection;
                margin-top: 0;
              }
            }
            input {
              @media (max-width: 48em) {
                clear: $ldirection;
              }
            }
          }
          .viewcart-bottom-buttons {
            @media (max-width: 48em) {
              padding: 1em 0;
              border-top: 1px solid $color-c1-white;
              margin-top: 1em;
            }
          }
          #sign-in-panel {
            .checkout__subtitle {
              @media (max-width: 48em) {
                margin-bottom: 1em;
              }
            }
          }
          .continue-buttons {
            a {
              @media (max-width: 48em) {
                max-width: 40%;
                margin: 12px 1em;
              }
            }
            .continue-checkout {
              @media (max-width: 48em) {
                float: $rdirection;
                font-size: 15px;
              }
            }
          }
          .continue-shopping {
            @media (max-width: 48em) {
              float: $ldirection;
              background: none;
              max-width: 40%;
              margin: 12px 1em;
              font-size: 12px;
              margin-#{$ldirection}: 0;
              padding: 5px;
              @if $cr19 == true {
                color: $cr19-text-black;
                border: 1px solid $cr19-border-black;
              } @else {
                color: $color-cl-green-2;
                border: 1px solid $color-cl-green-2;
              }
            }
          }
          #review-panel {
            .order_terms {
              input[type='submit'] {
                @media (max-width: 48em) {
                  margin-top: 4px;
                }
              }
            }
          }
          .messages {
            @media (max-width: 48em) {
              padding: 0 1em;
              border: 0;
              margin-top: 5px;
            }
          }
          .panel {
            .viewcart-header {
              @media (max-width: 48em) {
                padding-bottom: 10px;
              }
            }
            .content {
              @media (max-width: 48em) {
                padding: 0.5em 1em;
              }
            }
            header {
              h2 {
                .expand-toggle {
                  @media (max-width: 48em) {
                    margin-top: 4px;
                  }
                }
                @media (max-width: 48em) {
                  margin: 0;
                  font-size: 26px;
                  line-height: get-line-height(16px, 26px);
                  padding: 0.3em 0.5em;
                }
              }
            }
            .panel-group {
              header {
                @media (max-width: 48em) {
                  border-top: 0;
                }
              }
              @media (max-width: 48em) {
                clear: both;
              }
            }
            &.collapsed {
              header {
                h2 {
                  @media (max-width: 48em) {
                    border-bottom: 1px solid $color-c1-white;
                    border-top: 1px solid $color-c1-white;
                    color: $color-darker-grey-3;
                  }
                }
              }
            }
            .cart-items {
              .cart-item {
                &:last-child {
                  @media (max-width: 48em) {
                    width: 100%;
                  }
                }
                &.myog {
                  @media (max-width: 48em) {
                    border-bottom: none;
                  }
                }
                @media (max-width: 48em) {
                  border-bottom: 1px solid $color-c1-white;
                  padding-bottom: 10px;
                }
              }
              .thumb {
                img {
                  @media (max-width: 48em) {
                    max-width: 100%;
                    height: auto;
                    vertical-align: middle;
                  }
                }
                @media (max-width: 48em) {
                  width: 35%;
                  float: $ldirection;
                  min-height: 100%;
                }
              }
              .product__desc {
                @media (max-width: 48em) {
                  padding: 20px 4px 0;
                  word-wrap: break-word;
                  width: 65%;
                  float: $ldirection;
                }
                .product_name {
                  a {
                    @media (max-width: 48em) {
                      color: $color-cl-soft-black;
                      font-size: 17px;
                      font-weight: bold;
                    }
                  }
                  .wp-desc {
                    @media (max-width: 48em) {
                      position: relative;
                      bottom: 20px;
                    }
                  }
                }
                .info {
                  > div {
                    @media (max-width: 48em) {
                      margin-bottom: 5px;
                    }
                    &.product_name {
                      @media (max-width: 48em) {
                        width: 86%;
                        word-wrap: break-word;
                      }
                    }
                  }
                  .cart-item__price {
                    @media (max-width: 48em) {
                      display: block;
                      margin: 15px 0;
                    }
                    .price-label {
                      @media (max-width: 48em) {
                        display: none;
                      }
                    }
                    .cart-item__total {
                      @media (max-width: 48em) {
                        font-weight: bold;
                      }
                    }
                  }
                  .sample-hidden {
                    @media (max-width: 48em) {
                      display: none;
                    }
                  }
                }
              }
              .product_subname {
                @media (max-width: 48em) {
                  display: block;
                  color: $color-cl-soft-black;
                  font-size: 17px;
                  font-weight: bold;
                }
              }
              .cart-item__price {
                @media (max-width: 48em) {
                  display: none;
                }
              }
              .qty {
                @media (max-width: 48em) {
                  width: 65%;
                  float: $rdirection;
                  padding: 10px 15px 0;
                }
              }
              .product_subname_myog {
                @media (max-width: 48em) {
                  position: relative;
                  #{$ldirection}: 39%;
                  bottom: 68px;
                  width: 40%;
                }
              }
              .cart-item__price_myog {
                @media (max-width: 48em) {
                  position: relative;
                  #{$ldirection}: 39%;
                  bottom: 112px;
                }
              }
              .cart-item__qty_myog {
                @media (max-width: 48em) {
                  position: relative;
                  #{$ldirection}: -2%;
                  bottom: 53px;
                }
              }
              .cart-item__total_myog {
                @media (max-width: 48em) {
                  #{$ldirection}: 39%;
                  bottom: 96px;
                }
              }
              .myog-remove {
                .myog_remove_link {
                  @media (max-width: 48em) {
                    position: relative;
                    #{$ldirection}: 5%;
                    bottom: 50px;
                  }
                }
              }
              .myog_edit_link {
                @media (max-width: 48em) {
                  position: relative;
                  #{$ldirection}: 38%;
                  bottom: 50px;
                }
              }
              .cart-item__qty-gp-item {
                @media (max-width: 48em) {
                  margin-#{$ldirection}: 0;
                }
              }
            }
          }
          #order-summary-panel {
            @include swap_direction(padding, 0 1em 1em 1em);
            .order-summary__content {
              #shipmethod-panel {
                @include swap_direction(padding, 0 1em);
              }
              @include swap_direction(padding, 0);
              header {
                h2 {
                  float: none;
                }
              }
              .label {
                text-align: $ldirection;
                width: 65%;
                margin-bottom: 5px;
                clear: $ldirection;
                float: $ldirection;
                padding-#{$ldirection}: 1em;
                &.total {
                  font-weight: bold;
                  background-color: $color-c1-white;
                  @include swap_direction(padding, 5px 1em);
                }
              }
              .value {
                font-weight: normal;
                width: 35%;
                float: $ldirection;
                text-align: $rdirection;
                padding-#{$rdirection}: 1em;
                &.total {
                  font-weight: bold;
                  background-color: $color-c1-white;
                  @include swap_direction(padding, 5px 1em);
                }
              }
            }
          }
          #signin-block input.return-user-email,
          #signin-block input.field,
          #new-account input.field {
            @media (max-width: 48em) {
              border-color: $color-light-gray;
              padding: 2px;
              margin: 0;
            }
            &.checkedEmpty {
              @media (max-width: 48em) {
                border-color: $color-red;
              }
            }
          }
          .city-state-zip {
            .postal-code {
              @media (max-width: 48em) {
                width: 50%;
              }
            }
            .city {
              .invalid_marker,
              .valid_marker {
                @media (max-width: 48em) {
                  #{$rdirection}: 18px;
                }
              }
            }
          }
        }
        #shipping-panel {
          #pickpoint_customer_info {
            margin-#{$ldirection}: 0;
          }
          .local-collection-button {
            color: $color-white;
            padding: 15px;
            cursor: pointer;
            display: inline-block;
            @if $cr19 == true {
              background-color: $cr19-bg-black;
            } @else {
              background-color: $color-cl-green-2;
            }
          }
          .select-address {
            input[type='radio'] {
              height: 1em;
              margin: 3px 10px 0 0;
              &:last-child {
                margin-#{$ldirection}: 15px;
              }
            }
            label {
              display: block;
            }
            .address-to-use,
            .checkbox,
            .ship_group_0 {
              label {
                display: inline-block;
              }
            }
            .form-item {
              &.select {
                label {
                  display: none;
                }
                &.state {
                  label {
                    display: block;
                  }
                  select {
                    width: 100%;
                  }
                }
              }
            }
            .country {
              .form-item {
                &.select {
                  label {
                    display: block;
                  }
                }
              }
            }
          }
          .gift-options {
            .sub-section {
              margin-#{$ldirection}: 0;
            }
            textarea {
              width: 100%;
            }
          }
          #continue-btn {
            text-align: $rdirection;
          }
          #shipping-panel-edit {
            float: $ldirection;
            clear: both;
            margin-#{$ldirection}: 1em;
          }
          .select-menu {
            width: 100%;
            margin-#{$ldirection}: 0;
          }
          #shipping-address-display {
            padding: 0 1em;
            header {
              display: none;
            }
          }
          .city_container {
            .loading-container {
              float: $ldirection;
              margin-top: 5px;
            }
          }
          .delivery-method {
            margin-bottom: 0;
          }
        }
        .continue-buttons {
          text-align: $rdirection;
        }
        @media (min-width: 40.0625em) {
          .continue-shopping {
            background: none;
            float: $ldirection;
            @if $cr19 == true {
              border: 1px solid $cr19-border-black;
              color: $cr19-text-black;
            } @else {
              border: 1px solid $color-cl-green-2;
              color: $color-cl-green-2;
            }
          }
        }
        #viewcart-panel {
          #viewcart-buttons {
            @media (max-width: 48em) {
              display: none;
            }
            .continue-buttons {
              display: block;
            }
            .start-shopping {
              height: 37px;
              line-height: get-line-height(17px, 41px);
              font-size: 17px;
              padding: 0 15px;
              text-transform: uppercase;
              color: $color-white;
              text-align: center;
              display: inline-block;
              letter-spacing: 1px;
              border: 0;
              font-family: $font-helvetica65-medium-verdana;
              cursor: pointer;
              background-color: $color-black;
            }
          }
        }
        #promo-message {
          margin: 0;
        }
        .panel {
          &:after {
            visibility: hidden;
            height: 0;
            content: '';
            display: table;
            clear: both;
          }
          header {
            h2 {
              font-size: 36px;
              line-height: get-line-height(16px, 38px);
              padding: 0.3em;
            }
          }
          label {
            color: $color-black;
          }
          .viewcart-header {
            border-top: 0;
            padding-bottom: 40px;
            padding-top: 10px;
          }
          &.edit {
            header,
            h2 {
              background-color: transparent;
            }
          }
          .item-count {
            display: none;
          }
          .cart-items {
            .products {
              width: 55%;
            }
            .thumb {
              width: 21%;
            }
            .qty {
              width: 15%;
            }
            .desc {
              width: 34%;
            }
            .product_subname_myog {
              position: relative;
              #{$ldirection}: 21%;
              bottom: 70px;
              width: 40%;
            }
            .cart-item__qty-gp-item {
              margin-#{$ldirection}: 30px;
            }
            .myog-remove {
              .myog_remove_link {
                position: relative;
                #{$ldirection}: 21%;
                bottom: 60px;
              }
            }
            .myog_edit_link {
              position: relative;
              #{$ldirection}: 21%;
              bottom: 60px;
            }
            .cart-item__qty_myog {
              position: relative;
              #{$ldirection}: 60%;
              bottom: 150px;
              height: 0;
            }
            .cart-item__price_myog {
              position: relative;
              #{$ldirection}: 70%;
              bottom: 154px;
              height: 0;
            }
            .cart-item__total_myog {
              position: relative;
              #{$ldirection}: 93%;
              bottom: 154px;
              height: 0;
            }
          }
          .product {
            .info {
              .cart-item__price {
                display: none;
              }
              .cart-item {
                &__remove-form {
                  padding-top: 5px;
                }
              }
            }
            .product_subname {
              display: none;
            }
            .remove_link {
              position: static;
            }
            .remove-sample {
              float: $ldirection;
              clear: both;
            }
          }
          .icon-heart {
            color: $color-light-grey;
            &:hover {
              color: $color-hot-pink;
              cursor: pointer;
            }
            &:before {
              content: '\f08a';
            }
          }
          &.recommended-products-panel {
            header {
              margin-top: 15px;
            }
          }
        }
        .panel-group {
          > .column {
            margin-bottom: 0;
            padding-bottom: 0;
          }
        }
        #review-panel {
          .content {
            .content {
              padding: 0;
            }
          }
          .payment-type {
            input[type='radio'] {
              float: $ldirection;
              display: inline-block;
              margin-top: 24px;
              margin-#{$rdirection}: 10px;
              margin-#{$ldirection}: 5px;
            }
            input[value='PAYMENT_OPTION_OI'] {
              margin-top: 0;
            }
            #form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_paypal_label {
              padding-top: 20px;
            }
            label {
              .text-label {
                display: block;
                margin-top: 6px;
              }
            }
          }
          .order_terms {
            margin-top: 15px;
            margin-bottom: 15px;
            input[type='checkbox'] {
              float: $ldirection;
              display: inline-block;
              margin-top: -5px;
              margin-#{$rdirection}: 10px;
              margin-#{$ldirection}: 5px;
            }
          }
          #continue-btn {
            text-align: $rdirection;
          }
        }
        .dbMsgBox {
          border-color: $color-shade-grey;
          border-style: solid;
          border-width: 1px 0;
          padding: 15px 1em 10px;
          font-size: 15px;
          font-family: $helvetica-light-font-family;
          color: $color-cl-dark-grey;
          font-weight: normal;
        }
        .field-container {
          clear: both;
          &:after {
            content: '';
            display: table;
            clear: both;
          }
        }
        .top-banner {
          padding: 1em;
          color: $color-cl-green;
        }
        @media (min-width: 40.0625em) {
          .field-container {
            max-width: 660px;
          }
          .field-container--grid {
            .form-item {
              &:nth-of-type(2n + 1),
              &.new-account__item:nth-of-type(2n + 1) {
                width: 50%;
                float: $ldirection;
                margin-#{$rdirection}: -100%;
                clear: none;
                padding-#{$rdirection}: 1em;
                clear: both;
              }
              &:nth-of-type(2n + 1) {
                .valid_marker,
                .invalid_marker {
                  #{$rdirection}: 18px;
                }
              }
              &:nth-of-type(2n + 2) {
                width: 50%;
                float: $rdirection;
                margin-#{$ldirection}: 0;
                margin-#{$rdirection}: 0;
                clear: none;
                padding-#{$rdirection}: 0;
              }
            }
          }
        }
        .registration-form-container {
          .password,
          .text {
            max-width: 400px;
            position: relative;
            margin-top: 15px;
            margin-bottom: 10px;
          }
        }
        .viewcart-banner {
          background: $color-cl-green-2;
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$color-light-cyan,endColorstr=$color-cl-green-2);
          background: -webkit-gradient(
            linear,
            #{$ldirection} top,
            #{$ldirection} bottom,
            from($color-light-cyan),
            to($color-cl-green-2)
          );
          background: -moz-linear-gradient(top, $color-light-cyan, $color-cl-green-2);
          padding: 15px;
          margin-bottom: 30px;
          line-height: 2;
          font-size: 25px;
          font-family: $helvetica-light-font-family;
          color: $color-white;
          font-weight: normal;
          clear: both;
          h3 {
            color: $color-cl-green;
            @media (max-width: 48em) {
              font-size: 15px;
            }
          }
          a {
            color: $color-white;
            text-decoration: underline;
          }
          @media (max-width: 48em) {
            text-align: center;
            font-size: 15px;
            margin-bottom: 0;
          }
        }
        #confirmation-page {
          #confirmation-panel {
            .actions {
              padding: 1em;
              margin: 0;
            }
          }
          #sign-in-confirmation-panel {
            input[type='password'] {
              margin-top: 7px;
            }
          }
        }
      }
      [dir='rtl'] {
        .checkout {
          .city-state-zip {
            .city {
              float: $rdirection;
              margin-#{$ldirection}: -100%;
              padding-#{$ldirection}: 1em;
            }
            .state {
              float: $rdirection;
              margin-#{$ldirection}: -100%;
              margin-#{$rdirection}: 50%;
              padding-#{$ldirection}: 1em;
            }
            .postal-code {
              float: $ldirection;
              padding-#{$ldirection}: 0;
            }
          }
          .field-container--grid {
            .form-item {
              &:nth-of-type(2n + 1) {
                float: $rdirection;
                margin-#{$ldirection}: -100%;
                padding-#{$ldirection}: 1em;
              }
              &:nth-of-type(2n + 2) {
                float: $ldirection;
                padding-#{$ldirection}: 0;
              }
            }
          }
        }
      }
      #gc_iframe {
        width: 90%;
        height: 100%;
        min-height: 360px;
      }
    }
  }
  body#payment,
  body#confirm,
  body#index {
    &.device-mobile {
      #top {
        #header {
          .btn_navigation {
            @media (max-width: 48em) {
              display: none;
            }
            &.block-template-site-logo-responsive-v1 {
              @media (max-width: 48em) {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  body#payment {
    #viewcart-shopping-bag-panel {
      .product_subname_myog {
        @media (max-width: 48em) {
          #{$ldirection}: 39%;
        }
      }
      .cart-item__price_myog {
        @media (max-width: 48em) {
          #{$ldirection}: 39%;
        }
      }
      .cart-item__total_myog {
        @media (max-width: 48em) {
          #{$ldirection}: 39%;
        }
      }
      .cart-item__qty_myog {
        @media (max-width: 48em) {
          #{$ldirection}: 0%;
        }
      }
    }
  }
  .device-mobile {
    #foreground-node {
      @media (max-width: 48em) {
        padding: 20px;
      }
      .close-container {
        @media (max-width: 48em) {
          position: absolute;
          top: 15px;
          #{$rdirection}: 20px;
        }
      }
    }
    .overlay-container {
      .loyalty-products {
        .product {
          @media (max-width: 48em) {
            width: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 960px) {
    .outer-wrap {
      padding: 0;
    }
  }
  .secure_message {
    .secure_popup {
      text-align: center;
      margin-top: 20px;
      input[type='submit'] {
        position: static;
      }
    }
  }
  .expand-toggle {
    background: transparent
      url('/sites/clinique/themes/cl_base/template_api/video_category_row_v1/video-category-row-expand-collapse-sprite.png')
      0 -20px no-repeat;
    cursor: pointer;
    float: $rdirection;
    height: 20px;
    width: 22px;
    margin-top: 4px;
    background-position: 0 0;
  }
  header {
    &.active {
      .expand-toggle {
        background: url('/media/export/images/qs-caret.png') no-repeat scroll 50% 0 transparent;
      }
    }
  }
  .samples-panel {
    .caret {
      top: 15px;
    }
    .expand-toggle {
      top: 15px;
    }
  }
  .messages {
    &.error {
      a {
        color: $color-cl-green-2;
      }
    }
  }
  .overlay-container {
    .loyalty-samples-panel {
      margin: 20px 0;
    }
    .loyalty-products {
      .offer {
        margin-bottom: 20px;
      }
      .product {
        float: $ldirection;
        width: 32%;
        margin-#{$rdirection}: 1%;
        text-align: center;
        height: 345px;
        position: relative;
        .product__image {
          img {
            width: 100%;
          }
        }
        .sample-select-button {
          color: $color-white;
          line-height: 1.5;
          padding: 5px 10px;
          font-weight: bold;
          display: block;
          text-align: center;
          position: absolute;
          bottom: 0;
          #{$ldirection}: 0;
          #{$rdirection}: 0;
          @if $cr19 == true {
            background-color: $cr19-bg-black;
          } @else {
            background-color: $color-cl-green-2;
          }
          &.disabled {
            background-color: $color-c1-white;
          }
        }
        .skus {
          &.disabled {
            .sample-select-button {
              background-color: $color-c1-white;
            }
          }
        }
        .product-size {
          display: none;
        }
        &.deactivate {
          .sample-select-button {
            background-color: $color-c1-white;
          }
        }
      }
    }
    .add-button {
      text-align: $rdirection;
      input[type='submit'] {
        padding: 12px 37px;
      }
    }
  }
  label {
    &.label {
      &.checkedEmpty {
        color: $color-red;
      }
    }
  }
  @media (min-width: 40.0625em) {
    body#payment {
      #main {
        &.single {
          .product {
            .thumb {
              width: 40%;
              height: 160px;
            }
            .price,
            .qty {
              width: auto;
            }
            .price-label {
              display: inline-block;
            }
            &.sample {
              .price {
                #{$ldirection}: 1px;
                bottom: 51px;
              }
            }
            .shade {
              width: 90px;
            }
          }
          #viewcart-shopping-bag-panel {
            .product_subname_myog {
              bottom: 135px;
            }
            .cart-item__price_myog {
              bottom: 107px;
            }
            .cart-item__qty_myog {
              bottom: 124px;
            }
            .cart-item__total_myog {
              bottom: 90px;
            }
          }
        }
      }
      #footer {
        .footer-bottom {
          .block {
            li {
              &.shopping-bag {
                display: none;
              }
            }
          }
        }
        .footer-top {
          .info-link {
            @media (max-width: 48em) {
              display: none;
            }
          }
        }
      }
      .page-footer-region {
        .page-footer {
          .no-sidebars {
            clear: both;
          }
        }
      }
    }
  }

  // CL Randomizer Game-Color change for Checkout Button when Disabled
  .responsive-container {
    .viewcart-panel {
      .continue-buttons {
        .disabled {
          background-color: $color-grey-light;
        }
      }
    }
    .checkout {
      &__sidebar {
        .disabled {
          background-color: $color-grey-light;
        }
      }
    }
  }
}
