.elc-product-full {
  .elc-vto-foundation {
    &-shade-grid {
      .elc-vto {
        &-tab {
          font-size: 12px;
          margin-bottom: 5px;
          white-space: normal;
        }
        &-perfect-shade-label {
          display: flex;
          font-size: 13px;
          justify-content: center;
          white-space: nowrap;
          width: 70px;
        }
        &-foundation-shade-grid {
          &.vto-foundation-lip {
            .elc-vto-product-name {
              height: 40px;
            }
          }
        }
        &-see-all-shades,
        &-see-perfect-shades {
          &.elc-button {
            [data-component] & {
              border: 0;
              font-size: 13px;
              line-height: normal;
              margin-#{$ldirection}: 5px;
              text-decoration: underline;
            }
          }
        }
        &-shade-info-right-container {
          width: 40%;
        }
        &-see-pair-product {
          border: 0;
          font-size: 14px;
          text-align: $rdirection;
          text-decoration: underline;
          width: 100%;
        }
      }
    }
    button.elc-add-to-bag-button {
      font-size: 14px;
      line-height: normal;
    }
    .elc-product-cta-wrapper {
      .elc-product-notify-me-button {
        font-size: 14px;
        height: 48px;
        line-height: normal;
      }
    }
  }
}
