.appt-book {
  #booking-step3 {
    .book-appt-container {
      .mobile-phone-fieldset {
        .appt-book-mobile-prefix {
          width: 20%;
          display: inline-block;
        }
        .appt-book-mobile {
          width: 80%;
          float: right;
        }
      }
      .registration__terms.error {
        color: $color-notice-pink;
        a {
          color: $color-notice-pink;
        }
      }
    }
    .start-over {
      width: 166px;
    }
  }
  #booking-step1 {
    .selects-container {
      .counter-select-container {
        padding-right: 0;
      }
    }
  }
  .appointment-container {
    .appt-date {
      p {
        display: inline-block;
      }
    }
  }
}

#appointments {
  .my-appointments-overlay {
    .overlay-content-container {
      .overlay-content.canceled {
        span {
          p {
            display: inline;
          }
        }
      }
    }
  }
}

.footer-links__link {
  span {
    display: none;
  }
}
