#loyalty__page__about,
.section-smartrewards {
  .loyalty_mrkt_ldng {
    .loyalty_market_sticky_nav {
      .loyalty_join_now,
      .loyalty_signin {
        width: 45%;
        .btn_join,
        .btn_sign_in {
          min-height: 52px;
          padding: 5px 10px;
          font-size: 15px;
        }
      }
      .loyalty_signin {
        float: $rdirection;
      }
      .loyality_sign_off {
        font-size: 16px;
      }
    }
    .loyalty_market_points_left {
      table {
        font-size: 15px;
      }
    }
    .hero-full-width {
      .content-block__line {
        &--eyebrow {
          display: none;
        }
      }
    }
    .editorial-grid-formatter {
      &__item {
        padding: 0;
      }
      .outer-wrap {
        padding: 0;
      }
    }
    .column-layout-standard {
      padding: 15px 0;
    }
    .field-mobile-template {
      .editorial-grid-formatter {
        padding: 0;
      }
    }
    .loyalty__panel__tier {
      li {
        text-indent: -16px;
      }
    }
    .content-block__line--headline {
      .title--large {
        p {
          font-size: 30px;
        }
      }
    }
  }
}
#loyalty__panel__benefits__content,
#loyalty__page__history,
#loyalty__panel__smart-rewards,
.loyalty-marketing-page {
  .loyalty__panel__tier {
    li {
      text-indent: -10px;
    }
  }
}
#index {
  #loyalty__page__history {
    .loyalty__panel__points__progessbar {
      width: 100%;
    }
    .loyalty__panel__transactions__list {
      margin-#{$ldirection}: 0;
    }
  }
}
#loyalty__page__index,
#loyalty__page__history {
  .next-tier-points-msg {
    margin-#{$ldirection}: 10px !important;
    width: 52%;
    float: $rdirection;
  }
}
#main {
  &.single {
    .loyalty-enrollment,
    .confirmation-panel__loyalty {
      padding: 1em;
    }
  }
}
